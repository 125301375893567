import React, { useState, useEffect } from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, MenuItem } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

const columnHelper = createMRTColumnHelper();

const columns = [
  {
    accessorKey: "_id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "clinicname",
    header: "Clinic Name",
    size: 40,
  },
  {
    accessorKey: "category",
    header: "Category",
    size: 40,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 40,
  },
  {
    accessorKey: "phone",
    header: "Phone Number",
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    size: 40,
  },
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const ClinicData = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data) {
      const transformedData = props.data.map((clinic) => {
        let categoryLabels = "";
        if (Array.isArray(clinic.categoryid)) {
          categoryLabels = clinic.categoryid
            .map((cat) => cat.categoryname || "")
            .join(", ");
        }
        return {
          ...clinic,
          category: categoryLabels,
        };
      });
      setData(transformedData);
    }
  }, [props.data]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleNavigate = (row, path) => {
    navigate(path, { state: { clinicid: row.original._id } });
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    options: {
      compression: true,
    },
    enableRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem
        key="view"
        onClick={() => handleNavigate(row, "/admins/clinicDetail")}
      >
        View
      </MenuItem>,
      <MenuItem key="delete">Delete</MenuItem>,
    ],
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

ClinicData.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ClinicData;
