import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SuperAdminContext from '../../../context/SuperAdminContext';

const ShowDoc = ({doc,clinicId}) => {
  const BASE_URL = process.env.REACT_APP_PUBLIC_URL;
    const a=useContext(SuperAdminContext);
    const {uploadDoc }=a
    const [document,setDocument]=useState(null)

    const handleChangeDoc =(event)=>{
        const docfile = event.target.files[0];
    setDocument(docfile);
    }

    const handleDocUpload=()=>{
        const formData = new FormData();
        formData.append("docfile", document);
        formData.append("clinicId", clinicId);
        console.log(formData)
        uploadDoc(formData);
        setDocument(null);
    }
    
  return (
    <div style={{ display: 'flex', flexDirection: 'column', }}>
      <Card style={{ width: '20rem', margin: '10px' }}>
          <Card.Img variant="top" src={`${BASE_URL}${doc.file_path.replace(/\\/g, '/')}`} alt={doc.alt || 'Image cap'} />
          <Card.Body>
            <Card.Title>{doc.file_name || 'Card Title'}</Card.Title>
          </Card.Body>
        </Card>

        <div className="form-group col-md-6 col-sm-6 col-xs-12">
        <div className="field-label">Change Document</div>
        <input
          type="file"
          name="file"
          placeholder=""
          onChange={handleChangeDoc}
        />
        {document && (
          <Button variant="secondary" onClick={handleDocUpload}>
            Upload
          </Button>
        )}
      </div>
    </div>
  
  )
}

export default ShowDoc
