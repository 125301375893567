import React from "react";
import RecomondTree from "./RecomondTree";

export default function RecomnDashContent() {
  return (
    <>
      <RecomondTree />
    </>
  );
}
