import React from "react";
import DashboardDataTwo from "../../../lookups/DashboardDataTwo";
/* import BarChart from "../charts/BarChart";
import DonutChart from "../charts/DonutChart"; */

export default function UsersearchHistory(props) {
  return (
    <>
      <div
        className={
          props.page === "usersearchHistory"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="usersearchHistory"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card dashboard-table">
              <div className="card-body">
                <h3>User's searches</h3>
                <DashboardDataTwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
