import React from "react";
import Sidenav from "./common/Sidenav";
import AdminDashContent from "./AdminDashContent";
import Profile from "../pages/Profile";
import ConsultationBookings from "../pages/ConsultationBookings";
import TreatmentBookings from "../pages/TreatmentBookings";
import ClinicList from "./ClinicList";
import ClicnicOnBoard from "./ClicnicOnBoard";
import MyProfile from "../pages/clinics/clinicprofile/MyProfile";
import ClinicDetails from "./ClinicDetails";

export default function AdminDashboard(props) {
  const mainpage = props.page;

  return (
    <>
      <section className="dashboard-section section-b-space">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidenav page={props.page} />
            </div>
            <div className="col-lg-10">
              <div className="faq-content tab-content">
                {mainpage === "adminDash" && (
                  <AdminDashContent page={props.page} />
                )}
                {mainpage === "profile" && <Profile page={props.page} />}
                {mainpage === "consultation-bookings" && (
                  <ConsultationBookings page={props.page} />
                )}
                {mainpage === "treatment-bookings" && (
                  <TreatmentBookings page={props.page} />
                )}
                {mainpage === "clinicLiist" && <ClinicList page={props.page} />}
                {mainpage === "clinicOnBoard" && (
                  <ClicnicOnBoard page={props.page} />
                )}
                {mainpage === "clinicDetail" && (
                  <ClinicDetails page={props.page} />
                )}

                {/*  <MyProfile /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  dashboard section end -->
    

  <!-- Modal start --> */}
    </>
  );
}
