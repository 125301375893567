import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = () => {
  // Sample data for the donut chart
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    labels: ["Label 1", "Label 2", "Label 3"],
    colors: ["#FF6384", "#36A2EB", "#FFCE56"],
  };

  const series = [30, 50, 20];

  return (
    <div>
      <h7>Donut Chart</h7>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={210}
      />
    </div>
  );
};

export default DonutChart;
