import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
export default function Loader() {
  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Stack spacing={1} mt={9}>
          <div className="row">
            <div className="offset-5">
              <Box sx={{ justifyContent: "center" }}>
                <CircularProgress> </CircularProgress>
              </Box>
            </div>
          </div>

          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="circular" width={100} height={100} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rounded" height={60} />
        </Stack>
      </Grid>
    </>
  );
}
