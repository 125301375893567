import React, { useState } from "react";
import "./TreeCard.css";

const TreeCard = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{ margin: "10px", border: "1px solid #ccc", padding: "10px" }}
      className="b-radius"
    >
      <div
        onClick={toggleCard}
        style={{ cursor: "pointer" }}
        className="card-header"
      >
        <strong>{title}</strong>
      </div>
      {isOpen && (
        <div style={{ marginLeft: "20px" }} className="card-children">
          {children}
        </div>
      )}
    </div>
  );
};

const RecomondTree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-3">
          {" "}
          {/* main cd */}
          <TreeCard title="Face">
            <TreeCard title="Eye Area">
              {/*  <TreeCard title="Grandchild 1.1.1" />
          <TreeCard title="Grandchild 1.1.2" />
          <TreeCard title="Grandchild 1.1.3" /> */}
            </TreeCard>
            <TreeCard title="Forehead,Nose,& Cheeks">
              <TreeCard title="Uneven skin tone" />
              <TreeCard title="Skin texture irregularities" />
              <TreeCard title="Undifined contour/ Hollowness" />
              <TreeCard title="Wrinkles and fine lines" />
            </TreeCard>
          </TreeCard>
        </div>
        <div className="col-3">
          <TreeCard title="Neck">
            <TreeCard title="Child 2.1">
              <TreeCard title="Grandchild 2.1.1" />
              <TreeCard title="Grandchild 2.1.2" />
            </TreeCard>
            <TreeCard title="Child 2.2">
              <TreeCard title="Grandchild 2.2.1" />
              <TreeCard title="Grandchild 2.2.2" />
            </TreeCard>
          </TreeCard>
        </div>
        <div className="col-3">
          <TreeCard title="Body">
            <TreeCard title="Body part -1" />

            {/*  <TreeCard title="Child 3.2">
          <TreeCard title="Grandchild 3.2.1" />
          <TreeCard title="Grandchild 3.2.2" />
        </TreeCard> */}
          </TreeCard>
        </div>
        <div className="col-3">
          <TreeCard title="Hair and Nails">
            <TreeCard title="Child 3.1">
              <TreeCard title="Grandchild 3.1.1" />
            </TreeCard>
            <TreeCard title="Child 3.2">
              <TreeCard title="Grandchild 3.2.1" />
              <TreeCard title="Grandchild 3.2.2" />
            </TreeCard>
          </TreeCard>
        </div>
      </div>
    </div>
  );
};

export default RecomondTree;
