import React from "react";

export default function ClicnicOnBoard() {
  return (
    <>
      <div class="container">
        <div class="checkout-page">
          <div class="checkout-form">
            <form>
              <div class="checkout-title">
                <h3>Clinics Details</h3>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <div className="card dashboard-table">
                    <div className="card-body">
                      <div class="row check-out">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Clinic Name</div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">
                            Contact persion full name
                          </div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Phone</div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Aletrnative Phone</div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Email Address</div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Banner Image</div>
                          <input
                            type="file"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">Clinic Document</div>
                          <input
                            type="file"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                          <div class="field-label">ID Number</div>
                          <input
                            type="text"
                            name="field-name"
                            value=""
                            placeholder=""
                          />
                        </div>
                        <div class="form-group col-md-12 col-sm-12 col-xs-12">
                          <div class="field-label">Description</div>
                          <textarea
                            name=""
                            id=""
                            cols="50"
                            rows="30"
                            placeholder="Text here"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <div class="checkout-details">
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                      <div class="field-label">Country</div>
                      <select>
                        <option>India</option>
                        <option>South Africa</option>
                        <option>United State</option>
                        <option>Australia</option>
                      </select>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                      <div class="field-label">Address</div>
                      <input
                        type="text"
                        name="field-name"
                        value=""
                        placeholder="Street address"
                      />
                    </div>
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                      <div class="field-label">Town/City</div>
                      <input
                        type="text"
                        name="field-name"
                        value=""
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-12 col-sm-6 col-xs-12">
                      <div class="field-label">State / County</div>
                      <input
                        type="text"
                        name="field-name"
                        value=""
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-12 col-sm-6 col-xs-12">
                      <div class="field-label">Postal Code</div>
                      <input
                        type="text"
                        name="field-name"
                        value=""
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input
                        type="checkbox"
                        name="shipping-option"
                        id="account-option"
                      />{" "}
                      &ensp;
                      <label for="account-option">Create An Account?</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col offset-md-4">
                  {" "}
                  <div class="text-right">
                    <a href="#" class="btn-solid btn">
                      Save details
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
