import React from "react";

export default function Profile(props) {
  return (
    <>
      <div
        className={
          props.page === "profile"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="profile"
      >
        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="dashboard-box">
                  <div className="dashboard-title">
                    <h4>profile</h4>
                    <span data-toggle="modal" data-target="#edit-profile">
                      edit
                    </span>
                  </div>
                  <div className="dashboard-detail">
                    <ul>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>company name</h6>
                          </div>
                          <div className="right">
                            <h6>Fashion Store</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>email address</h6>
                          </div>
                          <div className="right">
                            <h6>mark.enderess@mail.com</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>Country / Region</h6>
                          </div>
                          <div className="right">
                            <h6>Downers Grove, IL</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>Year Established</h6>
                          </div>
                          <div className="right">
                            <h6>2018</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>Total Employees</h6>
                          </div>
                          <div className="right">
                            <h6>101 - 200 People</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>category</h6>
                          </div>
                          <div className="right">
                            <h6>clothing</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>street address</h6>
                          </div>
                          <div className="right">
                            <h6>549 Sulphur Springs Road</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>city/state</h6>
                          </div>
                          <div className="right">
                            <h6>Downers Grove, IL</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="details">
                          <div className="left">
                            <h6>zip</h6>
                          </div>
                          <div className="right">
                            <h6>60515</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
