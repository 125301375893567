import React, { useEffect, useContext } from "react";
import Header from "./common/Header";
import Mobilenav from "./common/Mobilenav";
import Footer from "./common/Footer";
import AdminDashboard from "./AdminDashboard";
import SuperAdminContext from "../../context/SuperAdminContext";
import { useNavigate } from "react-router-dom";
import Alerts from "../notify/Alerts";

export default function AdminsLayout(props) {
  const a = useContext(SuperAdminContext);
  const navigate = useNavigate();
  const { islogin, alert } = a;
  useEffect(() => {
    if (!islogin) {
      navigate("/login", { replace: true });
    }
  }, [islogin]);
  const logowidth = {
    width: "17%",
  };
  return (
    <>
      {/* <!-- header start --> */}
      {alert.type && <Alerts alertMsg={alert.Message} alertType={alert.type} />}
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main-menu">
                <div className="menu-left">
                  {/* Drawer mobile */}
                  {/* <Mobilenav /> */}
                  {/* Drawer end */}
                  <div className="brand-logo">
                    <a href="index.html">
                      <img
                        src="../assets/images/icon/logo.jpeg"
                        className="img-fluid blur-up lazyload"
                        alt=""
                        style={logowidth}
                      />
                    </a>
                  </div>
                </div>
                {/* Desktop header */}
                <Header page={props.page} />
                {/* Desktop */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- header end -->


    <!-- breadcrumb start --> */}

      {/*  <!-- breadcrumb End -->


    <!--  dashboard section start --> */}
      <br />
      <br />
      <br />
      <br />
      <AdminDashboard page={props.page} />

      {/* <!-- modal end -->


    <!-- footer start --> */}
      <Footer />
    </>
  );
}
