import React, { useState, useContext, useEffect } from "react";
/* import Button from "react-bootstrap/Button"; */
import Modal from "react-bootstrap/Modal";
import Datacategories from "../../lookups/Datacategories";
/* import { addCategory } from "../../actions/AuperadminAction"; */
/* import { useDispatch, useSelector } from "react-redux"; */
import SuperAdminContext from "../../context/SuperAdminContext";
import Loader from "../notify/Loader";
import CircularProgress from "@mui/material/CircularProgress";

export default function Categories(props) {
  const a = useContext(SuperAdminContext);
  const {
    addCategory,
    data,
    fetcher,
    savedResponse,
    isLoading,
    deleteCategory,
    editCategory,
  } = a;
  /* const dispatch = useDispatch(); */
  const [lgShow, setLgShow] = useState(false);
  const [lgShowEdit, setLgShowEdit] = useState(false);
  const [category, setCategory] = useState("");
  const [editData, setEditData] = useState({
    categoryname: "",
    categoryid: "",
  });

  useEffect(() => {
    if (savedResponse) {
      setLgShow(false);
      setCategory("");
    }
    fetcher("category/show");
  }, [savedResponse]);

  const handlecategoryValue = (event) => {
    setCategory(event.target.value);
  };
  const handlecategoryValueEdit = (event) => {
    setEditData({
      ...editData,
      categoryname: event.target.value,
    });
  };
  const handleSubmit = () => {
    addCategory(category);
  };
  const handleDeleteCategory = (data) => {
    deleteCategory(data);
  };

  const handleEditCategory = (data) => {
    console.log("edit data ------------------->", data);
    setEditData({
      categoryname: data.categoryname,
      categoryid: data._id,
    });
    setLgShowEdit(true);
  };

  const handleSubmitEdit = () => {
    editCategory(editData);
    setLgShowEdit(false);
  };

  return (
    <>
      {/* Modals */}

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add new Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Department Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Department"
              value={category}
              onChange={handlecategoryValue}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="file" class="form-control" />
            <small id="emailHelp" class="form-text text-muted">
              File type jpg,png,jpeg max:100 mb
            </small>
          </div>

          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>

      {/* Edit  */}
      <Modal
        size="lg"
        show={lgShowEdit}
        onHide={() => setLgShowEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Department Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Department"
              value={editData.categoryname}
              onChange={handlecategoryValueEdit}
            />
          </div>
          {/* <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="file" class="form-control" />
            <small id="emailHelp" class="form-text text-muted">
              File type jpg,png,jpeg max:100 mb
            </small>
          </div> */}

          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmitEdit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>
      {/* End */}

      <div
        className={
          props.page === "categories"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="products"
      >
        <div className="row">
          <div className="col-12">
            <div className="card dashboard-table mt-0">
              <div className="card-body">
                <div className="top-sec">
                  <h3>All Departments</h3>
                  <a
                    href="#"
                    className="btn btn-sm btn-solid"
                    onClick={() => setLgShow(true)}
                  >
                    add department
                  </a>
                </div>
                {!data.Success ? (
                  <Loader />
                ) : (
                  <Datacategories
                    data={data.resultData}
                    deletecategory={handleDeleteCategory}
                    editcategory={handleEditCategory}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
