import * as React from "react";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
//import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const vertical = "top";
const horizontal = "right";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (props.alertType && props.alertMsg) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {/*  <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={props.alertType}
          sx={{ width: "100%" }}
        >
          {props.alertMsg}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
