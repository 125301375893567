import React from "react";
import Sidenav from "./common/Sidenav";
import { useLocation } from "react-router-dom";
import AddDashContent from "./AddDashContent";
import Banners from "./Banners";

export default function AddDash(props) {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  return (
    <>
      <section className="dashboard-section section-b-space">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidenav page={props.page} />
            </div>
            <div className="col-lg-10">
              <div className="faq-content tab-content">
                {lastSegment === "add-home" && (
                  <AddDashContent page={props.page} />
                )}

                {lastSegment === "banners" && <Banners page={props.page} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
