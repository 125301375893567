import React, { useState, useContext, useEffect } from "react";
/* import Button from "react-bootstrap/Button"; */
import Modal from "react-bootstrap/Modal";
import DataSubcategories from "../../lookups/DataSubcategories";
/* import { addCategory } from "../../actions/AuperadminAction"; */
/* import { useDispatch, useSelector } from "react-redux"; */
import SuperAdminContext from "../../context/SuperAdminContext";
import Loader from "../notify/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Subcategories(props) {
  const a = useContext(SuperAdminContext);
  const {
    addCategory,
    data,
    fetcher,
    isLoading,
    deleteSubCategory,
    editsubCategory,

    getcategoriesdropdown,
    categoryDropDown,
    addSubCategory,
    savedResponse,
  } = a;
  const [lgShow, setLgShow] = useState(false);
  const [lgShowEdit, setLgShowEdit] = useState(false);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [price, setprice] = useState("");
  const [editData, setEditData] = useState({
    categoryname: "",
    categoryid: "",
  });

  useEffect(() => {
    if (savedResponse) {
      setLgShow(false);
      setCategory("");
      setprice("");
      setSubcategory("");
    }
    fetcher("category/sub/getSubcategory");
  }, [savedResponse]);

  const handlecategoryValue = (event) => {
    setSubcategory(event.target.value);
  };
  const handlecategoryValueEdit = (event) => {
    setEditData({
      ...editData,
      categoryname: event.target.value,
    });
  };
  const handleSubmit = () => {
    addSubCategory(category, subcategory, price);
  };
  const handleDeleteCategory = (data) => {
    deleteSubCategory(data);
  };

  const handleEditCategory = (data) => {
    setEditData({
      categoryname: data.subcategoryname,

      categoryid: data._id,
    });
    setLgShowEdit(true);
  };

  const handleSubmitEdit = () => {
    editsubCategory(editData);
    

    setLgShowEdit(false);
  };

  const handleAddsubcategory = () => {
    getcategoriesdropdown();
    setLgShow(true);
  };
  const handlecategoryChange = (event, category, name) => {
    setCategory(category.categoryid);
  };

  const handleprice = (event) => {
    setprice(event.target.value);
  };

  return (
    <>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add new Sub Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Category</label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categoryDropDown}
              sx={{ width: 300, backgroundColor: "white" }}
              onChange={(event, newValue) =>
                handlecategoryChange(event, newValue, "category")
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Sub-Department Name</label>

            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Department"
              value={subcategory}
              onChange={handlecategoryValue}
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Price</label>

            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Price"
              value={price}
              onChange={handleprice}
            />
          </div>


          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lgShowEdit}
        onHide={() => setLgShowEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add new Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Department Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Department"
              value={editData.categoryname}
              onChange={handlecategoryValueEdit}
            />
          </div>

          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmitEdit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>

      <div
        className={
          props.page === "sub_category"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="products"
      >
        <div className="row">
          <div className="col-12">
            <div className="card dashboard-table mt-0">
              <div className="card-body">
                <div className="top-sec">
                  <h3>All Sub-Departments</h3>
                  <a
                    href="#"
                    className="btn btn-sm btn-solid"
                    onClick={handleAddsubcategory}
                  >
                    add sub-department
                  </a>
                </div>
                {data.Status == 100 ? (
                  <Loader />
                ) : (
                  <DataSubcategories
                    data={data.resultData}
                    deletecategory={handleDeleteCategory}
                    editcategory={handleEditCategory}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
