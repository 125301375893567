import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="footer-light">
        <div className="light-layout"></div>
      </footer>
      {/* <!-- footer end -->


    <!-- tap to top --> */}

      {/* <!-- tap to top End -->


    <!-- theme setting --> */}
    </>
  );
}
