import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = () => {
  // Sample data for the bar chart
  const options = {
    chart: {
      type: "bar",
      horizontal: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },

    xaxis: {
      categories: [
        "Category 1",
        "Category 2",
        "Category 3",
        "Category 4",
        "Category 5",
      ],
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [44, 55, 41, 67, 22],
    },
    {
      name: "Series 2",
      data: [13, 23, 20, 8, 13],
    },
  ];

  return (
    <div>
      <h7>Horizontal Bar Chart</h7>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={200}
      />
    </div>
  );
};

export default BarChart;
