import React, { useEffect, useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SuperAdminContext from "../../context/SuperAdminContext";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useNavigate } from "react-router-dom";
import { styled, lighten, darken } from "@mui/system";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const options = [
  { cat: "Group 1", key: { name: "Option 1", id: "1" } },
  { cat: "Group 1", key: { name: "Option 2", id: "2" } },
  { cat: "Group 1", key: { name: "Option 3", id: "3" } },
  { cat: "Group 2", key: { name: "Option 4", id: "4" } },
  { cat: "Group 2", key: { name: "Option 5", id: "5" } },
  { cat: "Group 2", key: { name: "Option 6", id: "6" } },
  { cat: "Group 2", key: { name: "Option 7", id: "7" } },
];

// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function ClicnicOnBoard() {
  const navigate = useNavigate();
  const a = useContext(SuperAdminContext);
  const {
    fetcher,
    data,
    subcategorydropdown,
    getSubcategorybycategory,
    saveClinic,
    isClinicSaved,
  } = a;
  const [categoryList, setCategoryList] = useState([]);
  const [clinic, setClinic] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [latlong, setLatLong] = useState({ lat: "", long: "" });
  const [about, setAbout] = useState("");
  const [country, setCountry] = useState("");
  const [totime, setToTime] = useState("");
  const [fromtime, setFromTime] = useState("");
  const [clinicCategory, setClinicCategory] = useState([]);
  const [imageFile, setImageFile] = useState("");
  const [documentFile, setDocumentFile] = useState([]);
  const [subactegory, setSubactegory] = useState([]);
  const [address, setAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [availabeityDay, setAvailabeityDay] = useState({
    fromday: "",
    today: "",
  });
  /* const [subcategorydropdown, setSubcategorydropdown] = useState([]); */

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetcher("category/show");
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchData();
  }, []);

  /* const optionstwo = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  }); */

  if (isClinicSaved) {
    navigate("/admins/clinicLiist", { replace: true });
  }

  const handleFormData = (event, newValue, dropdownName) => {
    console.log("selected value-------->", newValue);
    setClinicCategory(newValue);
    getSubcategorybycategory(newValue);
  };
  const handleSubcategory = (event, selectedValues, dropdownName) => {
    setSubactegory(selectedValues);
    console.log("subcategores----------->", selectedValues);
  };

  const handleClinic = (event) => {
    setClinic(event.target.value);
  };

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  console.log('onbord',localStorage);
  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleLatLong = (event, label) => {
    setLatLong({
      ...latlong,
      [label]: event.target.value,
    });
  };

  const handleAbout = (event) => {
    setAbout(event.target.value);
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleaddress = (event, label) => {
    setAddress({
      ...address,
      [label]: event.target.value,
    });
  };

  const handleToTime = (event, newValue) => {
    console.log("new time --------->", newValue);
    setToTime(newValue);
  };

  const handleFromTime = (event, newValue) => {
    setFromTime(newValue);
    console.log("new time --------->", newValue);
  };

  const handleDayAvailable = (event, label) => {
    setAvailabeityDay({
      ...availabeityDay,
      [label]: event.target.value,
    });
  };

  const handleBannerImageChange = async (event) => {
    const file = event.target.files[0];

    console.log("filesdd ------>", file);
    setImageFile(file);
  };

  const handleDocumentChange = async (event) => {
    const file = event.target.files[0];
    setDocumentFile(file);
  };

  const handleClinciSubmit = () => {
    const formData = new FormData();
    console.log("category-------->", clinicCategory);
    console.log("subcategory-------->", subactegory);
    console.log("clinic-------->", clinic);
    console.log("website-------->", website);
    console.log("email-------->", email);
    console.log("phone-------->", phone);
    console.log("latlong-------->", latlong);
    console.log("about-------->", about);
    console.log("country-------->", country);
    console.log("totime-------->", totime);
    console.log("fromtime-------->", fromtime);
    console.log("address-------->", address);
    console.log("availabeityDay-------->", availabeityDay);
    console.log("image-------->", imageFile);

    formData.append("clinicname", clinic);
    formData.append("categoryid", JSON.stringify(clinicCategory));
    formData.append("subcategories", JSON.stringify(subactegory));
    formData.append("website", website);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("lat", latlong.lat);
    formData.append("long", latlong.lat);
    formData.append("about", about);
    formData.append("country", country);
    formData.append("totime", totime);
    formData.append("fromtime", fromtime);
    formData.append("address", JSON.stringify(address));
    formData.append("fromday", availabeityDay.fromday);
    formData.append("today", availabeityDay.fromday);

    formData.append("file", imageFile);
    formData.append("docfile", documentFile);
    saveClinic(formData);
  };

  const isOptionEqualToValue = (option, value) => {
    return (
      option.label === value.label && option.categoryid === value.categoryid
    );
  };

  /* setAdminData({
    ...adminData,
    [label]: value,
  }); */

  if (data.Success) {
    let dropdown = [];
    data.resultData.map(
      (category, index) =>
        (dropdown[index] = {
          label: category.categoryname,
          categoryid: category._id,
        })
    );
    return (
      <>
        <div className="container">
          <div className="checkout-page">
            <div className="checkout-form">
              <form>
                <div className="checkout-title">
                  <h3>Register Clinic</h3>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className="card dashboard-table">
                      <div className="card-body">
                        <div className="row check-out">
                          {/* <div className="field-label">Select Category</div> */}
                        </div>

                        <div className="row check-out">
                          {/* <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Select Category</div>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropdown}
                              sx={{ backgroundColor: "white" }}
                              onChange={(event, newValue) =>
                                handleFormData(event, newValue, "billtype")
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Category" />
                              )}
                            />
                          </div> */}
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Select Categories</div>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={dropdown}
                              disableCloseOnSelect
                              isOptionEqualToValue={isOptionEqualToValue}
                              sx={{ backgroundColor: "white" }}
                              onChange={(event, newValue) =>
                                handleFormData(event, newValue, "billtype")
                              }
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Category"
                                  placeholder="Favorites"
                                />
                              )}
                            />
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">
                              Select Subcategory
                            </div>
                            <Autocomplete
                              multiple
                              id="category-autocomplete"
                              options={subcategorydropdown}
                              groupBy={(option) => option.cat} // Group by category
                              getOptionLabel={(option) => option.key.name} // Display option key
                              onChange={(event, newValue) =>
                                handleSubcategory(event, newValue, "billtype")
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.key.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Sub-Categories"
                                />
                              )}
                            />
                            {/* <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={top100Films}
                              disableCloseOnSelect
                              sx={{ backgroundColor: "white" }}
                              onChange={handleOnChange}
                              getOptionLabel={(option) => option.title}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Checkboxes"
                                  placeholder="Favorites"
                                />
                              )}
                            /> */}
                            {/* <Autocomplete
                              multiple
                              id="grouped-demo"
                              options={optionstwo.sort(
                                (a, b) =>
                                  -b.firstLetter.localeCompare(a.firstLetter)
                              )}
                              groupBy={(option) => option.firstLetter}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="With categories"
                                />
                              )}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader>{params.group}</GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                </li>
                              )}
                            /> */}
                          </div>

                          <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <div className="field-label">Clinic Name</div>
                            <input
                              type="text"
                              name="field-name"
                              value={clinic}
                              placeholder=""
                              onChange={handleClinic}
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <div className="field-label">Website</div>
                            <input
                              type="text"
                              name="field-name"
                              value={website}
                              placeholder=""
                              onChange={handleWebsite}
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <div className="field-label">Phone</div>
                            <input
                              type="text"
                              name="field-name"
                              value={phone}
                              placeholder=""
                              onChange={handlePhone}
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <div className="field-label">Email Address</div>
                            <input
                              type="text"
                              name="field-name"
                              value={email}
                              placeholder=""
                              onChange={handleEmail}
                            />
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">availability</div>
                            <div className="row">
                              <div className="col-6">
                                <div className="field-label">From day</div>
                                <select
                                  onChange={(event) =>
                                    handleDayAvailable(event, "fromday")
                                  }
                                  style={{ backgroundColor: "white" }}
                                >
                                  <option value="" selected disabled>
                                    Days
                                  </option>
                                  <option value="Monday">Monday</option>
                                  <option value="Tuesday">Tuesday</option>
                                  <option value="Wednesday">Wednesday</option>
                                  <option value="Thrusday">Thrusday</option>
                                  <option value="Friday">Friday</option>
                                  <option value="Saturday">Saturday</option>
                                  <option value="Sunday">Sunday</option>
                                </select>
                              </div>
                              <div className="col-6">
                                <div className="field-label">To day</div>
                                <select
                                  onChange={(event) =>
                                    handleDayAvailable(event, "today")
                                  }
                                  style={{ backgroundColor: "white" }}
                                >
                                  <option value="" selected disabled>
                                    Days
                                  </option>
                                  <option value="Monday">Monday</option>
                                  <option value="Tuesday">Tuesday</option>
                                  <option value="Wednesday">Wednesday</option>
                                  <option value="Thrusday">Thrusday</option>
                                  <option value="Friday">Friday</option>
                                  <option value="Saturday">Saturday</option>
                                  <option value="Sunday">Sunday</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">availability</div>
                            <div className="row">
                              <div className="col-6">
                                <div className="field-label">To time</div>
                                {/* <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Basic time picker"
                                      onChange={handleToTimeChange}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider> */}

                                <Autocomplete
                                  id="disabled-options-demo"
                                  options={timeSlots}
                                  getOptionDisabled={(option) =>
                                    option === timeSlots[0] ||
                                    option === timeSlots[2]
                                  }
                                  onChange={handleToTime}
                                  sx={{ backgroundColor: "white" }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Time" />
                                  )}
                                />
                              </div>
                              <div className="col-6">
                                <div className="field-label">From time</div>
                                {/* <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Basic time picker"
                                      onChange={handleFromTimeChange}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider> */}

                                <Autocomplete
                                  id="disabled-options-demo"
                                  options={timeSlots}
                                  getOptionDisabled={(option) =>
                                    option === timeSlots[0] ||
                                    option === timeSlots[2]
                                  }
                                  onChange={handleFromTime}
                                  sx={{ backgroundColor: "white" }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Time" />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <div className="field-label">Banner Image</div>
                            <input
                              type="file"
                              name="file"
                              /* value="" */
                              placeholder=""
                              onChange={handleBannerImageChange}
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <div className="field-label">Clinic Document</div>
                            <input
                              type="file"
                              name="docfile"
                              /* value="" */
                              placeholder=""
                              onChange={handleDocumentChange}
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <div className="field-label">Lat,Long</div>
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type="text"
                                  name="lat"
                                  value={latlong.lat}
                                  placeholder="Latitude"
                                  onChange={(event) =>
                                    handleLatLong(event, "lat")
                                  }
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  name="long"
                                  value={latlong.long}
                                  placeholder="Longitude"
                                  onChange={(event) =>
                                    handleLatLong(event, "long")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-xs-12">
                    <div className="checkout-details">
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">About</div>
                        <textarea
                          name=""
                          id=""
                          cols="50"
                          rows="30"
                          placeholder="About.."
                          value={about}
                          onChange={handleAbout}
                        ></textarea>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">Country</div>
                        <select onChange={handleCountry}>
                          <option value="" selected disabled>
                            Countries
                          </option>
                          <option value="India">India</option>
                          <option value="South Africa">South Africa</option>
                          <option value="United State">United State</option>
                          <option value="Australia">Australia</option>
                        </select>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">Address</div>
                        <input
                          type="text"
                          name="field-name"
                          value={address.address}
                          placeholder="Street address"
                          onChange={(event) => handleaddress(event, "address")}
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">Town/City</div>
                        <input
                          type="text"
                          name="field-name"
                          value={address.city}
                          placeholder="City"
                          onChange={(event) => handleaddress(event, "city")}
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-6 col-xs-12">
                        <div className="field-label">State</div>
                        <input
                          type="text"
                          name="field-name"
                          value={address.state}
                          placeholder="State"
                          onChange={(event) => handleaddress(event, "state")}
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-6 col-xs-12">
                        <div className="field-label">Postal Code</div>
                        <input
                          type="text"
                          name="field-name"
                          value={address.zip}
                          placeholder="Zip"
                          onChange={(event) => handleaddress(event, "zip")}
                        />
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <input
                          type="checkbox"
                          name="shipping-option"
                          id="account-option"
                        />{" "}
                        &ensp;
                        <label for="account-option">Create An Account?</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col offset-md-4">
                    {" "}
                    <div className="text-right">
                      <a
                        href="#"
                        className="btn-solid btn"
                        onClick={handleClinciSubmit}
                      >
                        Save details
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
