import React from "react";
import Sidenav from "../common/Sidenav";
import Admins from "./Admins";
import Categories from "./Categories";
import ConsultationBookings from "./ConsultationBookings";
import DashboardContent from "./DashboardContent";
import Profile from "./Profile";
import Settings from "./Settings";
import Subcategories from "./Subcategories";
import TreatmentBookings from "./TreatmentBookings";
import UdashContent from "./users/UdashContent";

export default function Dashboard(props) {
  console.log("page--------->", props);
  const mainpage = props.page;
  return (
    <>
      <section className="dashboard-section section-b-space">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidenav page={props.page} />
            </div>
            <div className="col-lg-10">
              <div className="faq-content tab-content">
                {mainpage === "dashboard" && (
                  <DashboardContent page={props.page} />
                )}

                {mainpage === "categories" && <Categories page={props.page} />}
                {mainpage === "admins" && <Admins page={props.page} />}
                {mainpage === "profile" && <Profile page={props.page} />}
                {mainpage === "settings" && <Settings page={props.page} />}
                {mainpage === "consultation-bookings" && (
                  <ConsultationBookings page={props.page} />
                )}
                {mainpage === "treatment-bookings" && (
                  <TreatmentBookings page={props.page} />
                )}
                {mainpage === "sub_category" && (
                  <Subcategories page={props.page} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  dashboard section end -->
      

    <!-- Modal start --> */}
    </>
  );
}
