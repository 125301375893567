import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Header(props) {
  console.log("page--------------->", props);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  console.log("currentPath----------->", currentPath);

  const handlenavigate = (path) => {
    navigate(path);
  };

  const headerStyle = {
    width: "100%",
    maxWidth: "800px", // Adjust this value as needed
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  };

  const navLinkMainStyle = {
    padding: "10px 15px", // Adjust padding for better spacing
  };

  const iconNavStyle = {
    display: "flex",
    listStyleType: "none",
  };

  const iconNavItemStyle = {
    marginLeft: "20px", // Adjust spacing between icons
  };

  const showDivStyle = {
    display: "none",
  };

  const onHoverDivStyle = {
    position: "relative",
  };

  const onHoverDivHoverStyle = {
    display: "block",
    position: "absolute",
    background: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: "10",
  };

  return (
    <>
      {/* Desktop header */}
      <div className="menu-right" style={headerStyle}>
        <div>
          <nav id="main-nav">
            <ul
              id="main-menu"
              className="sm pixelstrap sm-horizontal"
              data-smartmenus-id="17045489986765017">
              <li>
                <div className="mobile-back text-right">
                  Back
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => handlenavigate("/home/dashboard")}
                  className={
                    currentPath === "home"
                      ? "nav-link-main active"
                      : "nav-link-main"
                  }
                  style={navLinkMainStyle}
                >
                  Home
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => handlenavigate("/users/users-home")}
                  className={
                    currentPath === "users"
                      ? "nav-link-main active"
                      : "nav-link-main"
                  }
                  style={navLinkMainStyle}
                >
                  Users
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => handlenavigate("/clinics/clinic-home")}
                  className={
                    currentPath === "clinics"
                      ? "nav-link-main active"
                      : "nav-link-main"
                  }
                  style={navLinkMainStyle}
                >
                  Clinics
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() =>
                    handlenavigate("/recomdation/recomdation-home")
                  }
                  className={
                    currentPath === "recomdation"
                      ? "nav-link-main active"
                      : "nav-link-main"
                  }
                  style={navLinkMainStyle}
                >
                  Recommendation
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => handlenavigate("/advertisement/banners")}
                  className={
                    currentPath === "advertisement"
                      ? "nav-link-main active"
                      : "nav-link-main"
                  }
                  style={navLinkMainStyle}
                >
                  Advertisement
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <div className="icon-nav">
            <ul style={iconNavStyle}>
              <li className="onhover-div mobile-cart" style={onHoverDivStyle}>
                <div>
                  <img
                    src="../assets/images/icon/service3.png"
                    className="img-fluid blur-up lazyload notification-icon"
                    alt=""
                  />{" "}
                  <i className="ti-shopping-cart"></i>
                </div>
                <ul className="show-div shopping-cart" style={showDivStyle}>
                  <li>
                    <div className="media">
                      <a href="#">
                        {/* <img
                          className="mr-3"
                          src="../assets/images/fashion/product/1.jpg"
                          alt="Generic placeholder image"
                        /> */}
                      </a>
                      <div className="media-body">
                        <a href="#">
                          <h4>Notification 1</h4>
                        </a>
                        <h4>
                          <span>Jon doe..</span>
                        </h4>
                      </div>
                    </div>
                    <div className="close-circle">
                      <a href="#">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <a href="#">
                        {/* <img
                          className="mr-3"
                          src="../assets/images/fashion/product/2.jpg"
                          alt="Generic placeholder image"
                        /> */}
                      </a>
                      <div className="media-body">
                        <a href="#">
                          <h4>Notification 2</h4>
                        </a>
                        <h4>
                          <span>Jon doe..</span>
                        </h4>
                      </div>
                    </div>
                    <div className="close-circle">
                      <a href="#">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="buttons">
                      <a href="#" className="checkout">
                        view All
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
