import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, MenuItem } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

const columnHelper = createMRTColumnHelper();

const columns = [
  {
    accessorKey: "_id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "fullname",
    header: "First Name",
    size: 40,
  },
  {
    accessorKey: "username",
    header: "Username",
    size: 40,
  },
  {
    accessorKey: "phonenumber",
    header: "Phone Number",
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    size: 40,
  },
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const AdminsData = (props) => {
  const data = props.data || []; // Ensure data is defined and is an array

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleEditAdmin = (data) => {
    props.handleEditAdmin(data);
  };

  const handleAdminDelete = (id) => {
    props.handleDeleteAdmin(id);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => handleEditAdmin(row.original)}>
        Edit
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => handleAdminDelete(row.original._id)}
      >
        Delete
      </MenuItem>,
    ],
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    }, // Customize the default page size and page index
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  // if (data.length === 0) {
  //   return <div>No data available</div>; 
  // }

  return <MaterialReactTable table={table} />;
};

export default AdminsData;
