import React, { useEffect, useState, useContext } from "react";
import SignInSide from "./components/SignInSide";
//import Dashboard from "./components/pages/Dashboard";
import { useNavigate } from "react-router-dom";
import Layout from "./components/pages/Layout";
import SuperAdminContext from "./context/SuperAdminContext";
import Alerts from "./components/notify/Alerts";
export default function Landing(props) {
  const a = useContext(SuperAdminContext);
  const { islogin, alert } = a;
  const navigate = useNavigate();
  const [userData, setUserData] = useState(islogin);

  useEffect(() => {
    if (!islogin) {
      navigate("/login", { replace: true });
    } else {
      if (localStorage.getItem("type") == 1) {
        navigate("/admins/adminDash", { replace: true });
      }
    }
  }, [islogin]);

  return (
    <>
      {alert.type && <Alerts alertMsg={alert.Message} alertType={alert.type} />}
      {islogin ? (
        <Layout key="dashboard" page={props.page} />
      ) : (
        <SignInSide key="login" />
      )}
    </>
  );
}
