import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SuperAdminContext from '../../../context/SuperAdminContext';

function ShowImage({ images, clinicId }) {
  const BASE_URL = process.env.REACT_APP_PUBLIC_URL;
  const { uploadImage, deleteImage } = useContext(SuperAdminContext);
  const [image, setImage] = useState(null);

  const handleAddImage = async (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleDelete = (index) => {
    deleteImage(clinicId, index);
  };

  const handleImageUpload = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("clinicId", clinicId);
    uploadImage(formData);
    setImage(null);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {images && images.length > 0 ? (
        images.map((image, index) => {
          let filePath = image.file_path.replace(/\\/g, '/');
          return (
            <Card key={index} style={{ width: '18rem', margin: '10px' }}>
              {image.file_path ? (
                <Card.Img variant="top" src={`${BASE_URL}${filePath}`} alt={image.alt || 'Image cap'} />
              ) : (
                <p>No image available</p>
              )}
              <Card.Body>
                <Card.Title>{image.file_name || 'Card Title'}</Card.Title>
                <Button variant="danger" onClick={() => handleDelete(index)}>Delete</Button>
              </Card.Body>
            </Card>
          );
        })
      ) : (
        <div className="form-group col-md-6 col-sm-6 col-xs-12">
          <div className="field-label">Add Image</div>
          <input
            type="file"
            name="file"
            onChange={handleAddImage}
          />
          {image && (
            <Button variant="secondary" onClick={handleImageUpload}>
              Upload
            </Button>

          )}
        </div>
      )}
      {/* Image upload section, shown regardless of whether there are images */}
      <div className="form-group col-md-6 col-sm-6 col-xs-12">
        <div className="field-label">Add Image</div>
        <input
          type="file"
          name="file"
          onChange={handleAddImage}
        />
        {image && (
          <Button variant="secondary" onClick={handleImageUpload}>
            Upload
          </Button>
        )}
      </div>
    </div>
  );
}

export default ShowImage;
