import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import DashboardDataTwo from "../../../lookups/DashboardDataTwo";
import BannerImages from "../BannerImages";
import SuperAdminContext from '../../../context/SuperAdminContext';
// import './YourComponent.css'; // Import your custom CSS file for styling
import './Banner.css';

export default function Banners(props) {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const a = useContext(SuperAdminContext);
  const { uploadBanner } = a;

  

  const handleAddImage = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);
      uploadBanner(formData);
      setSelectedImage(null);
      setPreview(null);
      setLgShow(false);
    }
  };

  return (
    <>
      <div
        className={
          props.page === "banners"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="banners"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card dashboard-table">
              <div className="card-body">
                <div className="top-sec">
                  <h3>Banners List</h3>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setLgShow(true)}
                  >
                    Add Banners
                  </Button>
                </div>
                <BannerImages />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label className="field-label">Upload Image</label>
                  <div className="image-upload-container">
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      onChange={handleAddImage}
                      className="form-control-file"
                    />
                    {preview && (
                      <div className="image-preview mt-3">
                        <img src={preview} alt="Preview" className="img-thumbnail" />
                      </div>
                    )}
                    {selectedImage && (
                      <Button variant="primary" onClick={handleImageUpload} className="upload-button mt-3">
                        Upload
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
