import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FreyaProf from "./FreyaProf";
import SuperAdminContext from "../../../context/SuperAdminContext";
export default function Sidenav(props) {
  const a = useContext(SuperAdminContext);
  const { islogin, alert, setLogin } = a;
  const navigate = useNavigate();
  const handlenavigate = (path) => {
    navigate(path);
  };

  const logout = () => {
    setLogin(false);
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  };

  return (
    <>
      <div className="dashboard-sidebar">
        <FreyaProf />
        <div className="faq-tab">
          <ul className="nav nav-tabs" id="top-tab" role="tablist">
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "adminDash" ? "nav-link active" : "nav-link"
                }
                href="#dashboard"
                onClick={() => handlenavigate("/admins/adminDash")}
              >
                dashboard
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "consultation-bookings"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="#consultation-bookings"
                onClick={() => handlenavigate("/admins/consultation-bookings")}
              >
                Consultation Bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "treatment-bookings"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="#treatment-bookings"
                onClick={() => handlenavigate("/admins/treatment-bookings")}
              >
                Treatment Bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "clinicOnBoard"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="#"
                onClick={() => handlenavigate("/admins/clinicOnBoard")}
              >
                On board Clinics
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "clinicLiist" ? "nav-link active" : "nav-link"
                }
                href="#"
                onClick={() => handlenavigate("/admins/clinicLiist")}
              >
                Clinics list
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "profile" ? "nav-link active" : "nav-link"
                }
                href="#profile"
                onClick={() => handlenavigate("/admins/profile")}
              >
                profile
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="modal"
                data-target="#logout"
                href="#"
              >
                logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="modal logout-modal fade"
        id="logout"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Logging Out
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Do you want to log out?</div>
            <div className="modal-footer">
              <a
                href="#"
                className="btn btn-dark btn-custom"
                data-dismiss="modal"
              >
                no
              </a>
              <a
                href="#"
                className="btn btn-solid btn-custom"
                data-dismiss="modal"
                onClick={logout}
              >
                yes
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
