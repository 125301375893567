import React, { useEffect, useContext, useState } from "react";
import UsersData from "../../../lookups/UsersData";
import SuperAdminContext from "../../../context/SuperAdminContext";
import Loader from "../../notify/Loader";

/* import BarChart from "../charts/BarChart";
import DonutChart from "../charts/DonutChart"; */

export default function UdashContent(props) {
  const a = useContext(SuperAdminContext);
  //const [userData, setuserData] = useState([]);
  const { fetcher, userData, userDash } = a;
  useEffect(() => {
    userDash("admins/getUsers/");
  }, []);

  return (
    <>
      <div
        className={
          props.page === "users-home"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="dashboard"
      >
        <div className="counter-section">
          <div className="row">
            <div className="col-md-4">
              {!userData.Success ? (
                <Loader />
              ) : (
                <div className="counter-box">
                  <img
                    src="../assets/images/icon/dashboard/order.png"
                    className="img-fluid"
                  />
                  <div>
                    <h3>{userData.resultData.count}</h3>
                    <h5>total users</h5>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-md-4">
              <div className="counter-box">
                <img
                  src="../assets/images/icon/dashboard/sale.png"
                  className="img-fluid"
                />
                <div>
                  <h3>12500</h3>
                  <h5>total Invoices</h5>
                </div>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="counter-box">
                <img
                  src="../assets/images/icon/dashboard/homework.png"
                  className="img-fluid"
                />
                <div>
                  <h3>50</h3>
                  <h5>Total searches this week</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card dashboard-table">
              <div className="card-body">
                <h3>Users list</h3>
                {!userData.Success ? (
                  <Loader />
                ) : (
                  <UsersData data={userData.resultData.users} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
