import React from "react";

export default function FreyaProf() {
  return (
    <>
      <div className="profile-top">
        <div className="profile-image">
            <img 
  src="../assets/images/icon/logo.jpeg" 
  alt="Company Logo" 
  style={{ 
    width: '100px', 
    height:'100px',
    marginBottom: '20px', 
    borderRadius: '5%' 
  }} 
/>
        </div>
        <div className="profile-detail">
          <h5>Freya</h5>
          <h6>Super Admin | Username</h6>
          {/* <h6>mark.enderess@mail.com</h6> */}
        </div>
      </div>
    </>
  );
}
