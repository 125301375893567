import React from "react";
import { useNavigate } from "react-router-dom";
import FreyaProf from "../../../common/FreyaProf";

export default function Sidenav(props) {
  const navigate = useNavigate();
  const handlenavigate = (path) => {
    navigate(path);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <>
      <div className="dashboard-sidebar">
        <FreyaProf />
        <div className="faq-tab">
          <ul className="nav nav-tabs" id="top-tab" role="tablist">
            <li className="nav-item">
              {/* <a
                data-toggle="tab"
                className={
                  props.page === "add-home" ? "nav-link active" : "nav-link"
                }
                href="#dashboard"
                onClick={() => handlenavigate("/advertisement/add-home")}
              >
                Dashboard
              </a> */}
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "banners" ? "nav-link active" : "nav-link"
                }
                href="#"
                onClick={() => handlenavigate("/advertisement/banners")}
              >
                Banners
              </a>
            </li>
            <li className="nav-item">
              <a
                data-toggle="tab"
                className={
                  props.page === "notification" ? "nav-link active" : "nav-link"
                }
                href="#"
                onClick={() => handlenavigate("/advertisement/notification")}
              >
                Push Notification
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="modal logout-modal fade"
        id="logout"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Logging Out
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Do you want to log out?</div>
            <div className="modal-footer">
              <a
                href="#"
                className="btn btn-dark btn-custom"
                data-dismiss="modal"
              >
                no
              </a>
              <a
                href="#"
                className="btn btn-solid btn-custom"
                data-dismiss="modal"
                onClick={logout}
              >
                yes
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
