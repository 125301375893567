import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/pages/Layout";
import SignInSide from "./components/SignInSide";
import Landing from "./Landing";
import UsersLanding from "./components/pages/users/UsersLanding";
import ExampleComponent from "./components/ExampleComponent";
import ClinicLanding from "./components/pages/clinics/ClinicLanding";
import RecomndationLayout from "./components/pages/recomndation/RecomndationLayout";
import AddLayout from "./components/pages/addvertisement/AddLayout";
import AdminsLayout from "./components/admins/AdminsLayout";
import DashboardState from "./context/SuperAdminState";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <>
      <DashboardState>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              index
              path="/"
              element={<Landing key="dashboard" page="dashboard" />}
            />
            <Route
              exact
              path="/home/dashboard"
              element={<Landing key="common" page="dashboard" />}
            />
            <Route exact path="/login" element={<Landing key="login" />} />
            <Route
              exact
              path="/home/categories"
              element={<Landing key="common" page="categories" />}
            />
            <Route
              exact
              path="/home/sub_category"
              element={<Landing key="common" page="sub_category" />}
            />

            <Route
              exact
              path="/home/admins"
              element={<Landing key="admins" page="admins" />}
            />
            <Route
              exact
              path="/home/consultation-bookings"
              element={
                <Landing
                  key="consultation-bookings"
                  page="consultation-bookings"
                />
              }
            />
            <Route
              exact
              path="/home/treatment-bookings"
              element={
                <Landing key="treatment-bookings" page="treatment-bookings" />
              }
            />
            <Route
              exact
              path="/home/profile"
              element={<Landing key="profile" page="profile" />}
            />
            <Route
              exact
              path="/home/settings"
              element={<Landing key="settings" page="settings" />}
            />
            {/* Users routes */}
            <Route
              exact
              path="/users/users-home"
              element={<UsersLanding key="ulansd" page="users-home" />}
            />
            <Route
              exact
              path="/users/usersearchHistory"
              element={
                <UsersLanding
                  key="usersearchHistory"
                  page="usersearchHistory"
                />
              }
            />
            {/* Clinics routes */}
            <Route
              exact
              path="/clinics/clinic-home"
              element={<ClinicLanding key="clinicHome" page="clinic-home" />}
            />
            <Route
              exact
              path="/clinics/clinicLiist"
              element={<ClinicLanding key="clinicHome" page="clinicLiist" />}
            />
            <Route
              exact
              path="/clinics/clinicOnBoard"
              element={<ClinicLanding key="clinicHome" page="clinicOnBoard" />}
            />
            {/* Recomandation route */}
            <Route
              exact
              path="/recomdation/recomdation-home"
              element={
                <RecomndationLayout
                  key="recomdation-home"
                  page="recomdation-home"
                />
              }
            />
            <Route
              exact
              path="/recomdation/engine"
              element={<RecomndationLayout key="engine" page="engine" />}
            />
            <Route
              exact
              path="/advertisement/add-home"
              element={<AddLayout key="engine" page="engine" />}
            />
            <Route
              exact
              path="/advertisement/banners"
              element={<AddLayout key="banners" page="banners" />}
            />
            {/* Admins route */}
            <Route
              exact
              path="/admins/adminDash"
              element={<AdminsLayout key="banners" page="adminDash" />}
            />
            <Route
              exact
              path="/admins/consultation-bookings"
              element={
                <AdminsLayout
                  key="consultation-bookings"
                  page="consultation-bookings"
                />
              }
            />
            <Route
              exact
              path="/admins/treatment-bookings"
              element={
                <AdminsLayout
                  key="treatment-bookings"
                  page="treatment-bookings"
                />
              }
            />
            <Route
              exact
              path="/admins/profile"
              element={<AdminsLayout key="profile" page="profile" />}
            />

            <Route
              exact
              path="/admins/clinicDetail"
              element={<AdminsLayout key="clinicDetail" page="clinicDetail" />}
            />

            <Route
              exact
              path="admins/clinicLiist"
              element={<AdminsLayout key="clinicLiist" page="clinicLiist" />}
            />
            <Route
              exact
              path="/admins/clinicOnBoard"
              element={<AdminsLayout key="clinicLiist" page="clinicOnBoard" />}
            />

            {/* End */}
          </Routes>
        </BrowserRouter>
        {/* <ExampleComponent /> */}
      </DashboardState>
    </>
  );
}

export default App;
