import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SuperAdminContext from '../../context/SuperAdminContext';
import { useDrag, useDrop } from 'react-dnd';
import DndContext from './DndContext';
import { apiPath } from "../../components/constents"
const ItemType = 'BANNER';

const BannerRow = ({ image, index, moveRow, handleLink, links, handleActivation, uploadBannerLink,updateIsClickAble }) => {
    const BASE_URL = process.env.REACT_APP_PUBLIC_URL;
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: ItemType,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { type: ItemType, id: image._id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <td>
            {image.isClickAble ? (
                    <button className="btn  btn-xs  btn-success" onClick={() => {
                        updateIsClickAble(image._id,image.isClickAble);
                        image.isClickAble= false;
                    }}>notclickble</button>
                ) : (
                    <button className="btn btn-xs btn-danger" onClick={() => {
                        updateIsClickAble(image._id,image.isClickAble);
                        image.isClickAble = true;
                    }}>Clickable</button>
                )}
            </td>
            <td>
                <img src={`${BASE_URL}${image.bannerImages?.file_path ? image.bannerImages.file_path.replace(/\\/g, '/') : ""}`} alt={image.bannerImages?.alt || 'Image'} style={{ width: '100px' }} />
            </td>
            <td>{image.bannerImages?.file_name || 'Untitled'}</td>
            <td>
                {image.isActive ? (
                    <button className="btn  btn-xs  btn-success" onClick={() => {
                        handleActivation(image._id, image.isActive);
                        image.isActive = false;
                    }}>Deactivate</button>
                ) : (
                    <button className="btn btn-xs btn-danger" onClick={() => {
                        handleActivation(image._id, image.isActive);
                        image.isActive = true;
                    }}>Activate</button>
                )}
            </td>
            <td>
                <input type="text" value={links[image._id] || ''} onChange={(event) => handleLink(image._id, event)} />
            </td>
            <td>
                <Button variant="secondary" onClick={() => uploadBannerLink(image._id, links[image._id])}>Update Link</Button>
            </td>
        </tr>
    );
};

const BannerImages = () => {
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);
    const [links, setLinks] = useState({});
    const [preview, setPreview] = useState(null);

    const a = useContext(SuperAdminContext);
    const { fetcher, data, ActivateBanner, uploadBannerLink,updateIsClickAble ,bannerUpdated } = a;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetcher("admins/getBannerImages");
            } catch (error) {
                console.error("Error fetching banner data:", error);
            }
        };
        fetchData();
    }, [bannerUpdated]);

    useEffect(() => {
        if (data && data.resultData) {
            setImages(data.resultData);
        }
    }, [data]);

   

    const handleLink = (id, event) => {
        setLinks({ ...links, [id]: event.target.value });
    };

   
    const handleActivation = (id, isActive) => {
        ActivateBanner(id, isActive);
    };
    const moveRow = (dragIndex, hoverIndex) => {
        const reorderedImages = Array.from(images);
        const [removed] = reorderedImages.splice(dragIndex, 1);
        reorderedImages.splice(hoverIndex, 0, removed);
        setImages(reorderedImages);
        // Create the payload with updated indexes
        const updatedIndex = reorderedImages.map((image, index) => ({ _id: image._id, newIndex: index + 1 })); // Start index from 1
    
        // Log the payload to ensure it's correct
        console.log("Updated Index Payload:", updatedIndex);
    
        try {
            fetch(apiPath + 'admins/updateBannerOrder', {
                method: 'post',
                headers: {
                    
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                   "Content-Type": "application/json",
                  },
                body: JSON.stringify({ banners: updatedIndex })
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }).then(data => {
                console.log("Successfully updated banner order:", data);
            }).catch(error => {
                console.error("Error updating banner order:", error);
            });
        } catch (error) {
            console.error("Error updating banner order:", error);
        }
    };
    
    

    if (!images || images.length === 0) {
        return <p>No images to display.</p>;
    }

    return (
        <>
        <DndContext>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>isClickable</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Activation</th>
                        <th>Link</th>
                        <th>Update Link</th>
                    </tr>
                </thead>
                <tbody>
                    {images.map((image, index) => (
                        <BannerRow
                            key={image._id}
                            index={index}
                            image={image}
                            moveRow={moveRow}
                            handleLink={handleLink}
                            links={links}
                            handleActivation={handleActivation}
                            uploadBannerLink={uploadBannerLink}
                            updateIsClickAble={updateIsClickAble}
                            
                        />
                    ))}
                </tbody>
            </Table>
        </DndContext>
 </>
    );
};

export default BannerImages;
