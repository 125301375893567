import React, { useState } from "react";
import SuperAdminContext from "./SuperAdminContext";
import { apiPath } from "../components/constents";

const SuperAdminState = (props) => {
  const [islogin, setLogin] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [alert, setAlert] = useState({ type: "", Message: "" });
  const [data, setData] = useState({ Success: false, Status: 100 });
  const [userData, setuserData] = useState({ Success: false, Status: 100 });
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  const [subcategorydropdown, setSubcategorydropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClinicSaved, setIsClinicSaved] = useState(false);
  const [savedResponse, setSavedResponse] = useState(false);
  const [clinicData, setclinicData] = useState({ Success: false, Status: 100 });
  const [isOldEmailVerified,setIsOldEmailVerified]=useState(false)
  const [isOtpVerified,setIsOtpVerified]=useState(false)
  const [isClinicDetailsChanged,setIsClinicDetailsChanged]=useState(false);
  const[subCategoryDetails,setSubCategoryDetails]=useState([]);
  const[bannerUpdated, setBannerUpdated]=useState(false)
  const [clinicHolidays, setclinicHolidays] = useState({
    Success: false,
    Status: 100});
    const [isHolidayDeleted, setIsHolidayDeleted]=useState(false);
  // Login api
  const userLogin = async (username, password) => {
    setLogin(false);
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      username: username,
      password: password,
    });
    const endPoint = apiPath + "/auth/user_login";
    let response = await fetch(endPoint, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    console.log(data);
    const ExpertUser = data.resultData.data.user_role;
    console.log("Expert User", ExpertUser);
    if (data.Success) {
      console.log("login success");
      localStorage.setItem("user_data", data.resultData.token);
      localStorage.setItem("is_login", data.resultData.Success);
      if (ExpertUser !== "") {
        localStorage.setItem("expert_user", ExpertUser);
      }
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  /* Add subacterory */
  const addSubCategory = async (category, subacterory, price) => {
    setSavedResponse(false);
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "category/sub/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categoryId: category,
          subcategoryname: subacterory,
          price: price,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
      setSavedResponse(true);
      fetcher("category/sub/getSubcategory");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* End */

  const getSubcategorybycategory = async (categoryId) => {
    const endPoint = "category/sub/getsubcategorybyCategory";
    setSubcategorydropdown([]);
    try {
      const response = await fetch(apiPath + endPoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categories: categoryId,
        }),
      });
      const data = await response.json();
      console.log("getSubcategorybycategory----------------->", data);
      if (!data.Success) {
        if (data.Status === 401) {
          console.log("logout----------------->");
          await logout();
        }
        throw new Error(data.message);
      }
      setSubcategorydropdown(data.resultData);
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  const addCategory = async (category) => {
    setSavedResponse(false);
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "category/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categoryname: category,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
      setSavedResponse(true);
      fetcher("category/show");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  const editAdmin = async (editdata) => {
    setSavedResponse(false);
    try {
      const response = await fetch(apiPath + "admins/editAdmin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editdata),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
      setSavedResponse(true);
      fetcher("category/show");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* Delete Category */
  const editCategory = async (editdata) => {
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "category/edit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categoryname: editdata.categoryname,
          categoryid: editdata.categoryid,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);

      fetcher("category/show");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  const adminSave = async (admindata) => {
    setIsLoading(true);
    setSavedResponse(false);
    try {
      const response = await fetch(apiPath + "auth/adminSave", {
        method: "POST",
        headers: {
          Accept: "application/json",
          //Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(admindata),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
      setSavedResponse(true);
      fetcher("admins/admins_getall");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  const saveClinic = async (formData) => {
    setIsClinicSaved(false);
    setIsLoading(true);

    try {console.log('ccc',formData);
      const response = await fetch(apiPath + "admin/save/clinic", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "*/*",
        },
        body: formData,
      });
      

      const data = await response.json();
      console.log(data);
      setIsLoading(false);

      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }

      console.log("Success: Clinic saved");
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);

      setIsClinicSaved(true);
    } catch (error) {
      console.log("Error saving clinic:", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  const deleteAdmin = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "admins/delete_admin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adminid: id,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);

      fetcher("admins/admins_getall");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  /* Delete Category */
  const deleteCategory = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "category/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categoryid: id,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);

      fetcher("category/show");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

  /* Delete sub category */
  const deleteSubCategory = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(apiPath + "category/sub/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subcategoryid: id,
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          await logout();
        }
        throw new Error(data.Message);
      }
      setAlert({ type: "success", Message: data.Message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);

      fetcher("category/sub/getSubcategory");
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* End */
  /* Get category dop down  */
  const getcategoriesdropdown = async () => {
    const endPoint = "category/show";
    setCategoryDropDown([]);
    try {
      const response = await fetch(apiPath + endPoint, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        /* body: JSON.stringify({
          categoryname: category,
        }), */
      });
      const data = await response.json();
      console.log("category drop down----------------->", data);
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          console.log("logout----------------->");
          await logout();
        }
        throw new Error(data.message);
      }
      let dropdown = [];
      data.resultData.map(
        (category, index) =>
          (dropdown[index] = {
            label: category.categoryname,
            categoryid: category._id,
          })
      );

      setCategoryDropDown(dropdown);
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* End */

  /* getclinic data */
  const getClinicDetails = async (endPoint, id) => {
    //const endPoint = "category/show";
    setclinicData({ Success: false, Status: 100 });
    try {
      const response = await fetch(apiPath + endPoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clinicid: id,
        }),
      });
      const data = await response.json();
      console.log("clinic details",data)
      setclinicData(data);

      if (!data.Success) {
        if (data.Status === 401) {
          console.log("logout----------------->");
          await logout();
        }
        throw new Error(data.message);
      }
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* End */

  const userDash = async (endPoint) => {
    setuserData({ Success: false, Status: 100 });
    console.log("endpoin------>", endPoint);
    try {
      setIsClinicSaved(false);
      setIsLoading(true);

      const response = await fetch(apiPath + endPoint, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        /* body: JSON.stringify({
          categoryname: category,
        }), */
      });
      const data = await response.json();
      setuserData(data);
      console.log("fetcher----------------->", data);
      setIsLoading(false);
      if (!userData.Success) {
        if (userData.Status === 401) {
          console.log("logout----------------->");
          await logout();
        }
        throw new Error(userData.Message);
      }
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };
  /* Fetch data */
  const fetcher = async (endPoint) => {
    setData({ Success: false, Status: 100 });
    console.log("endpoin------>", endPoint);
    try {
      setIsClinicSaved(false);
      setIsLoading(true);

      const response = await fetch(apiPath + endPoint, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        /* body: JSON.stringify({
          categoryname: category,
        }), */
      });
      const data = await response.json();
      setData(data);
      console.log("fetcher----------------->", data);
      setIsLoading(false);
      if (!data.Success) {
        if (data.Status === 401) {
          console.log("logout----------------->");
          await logout();
        }
        throw new Error(data.Message);
      }
    } catch (error) {
      console.log("error catch------------->", error.message);
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    }
  };

const saveAvailibility = async (Data) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);

  try {
    console.log("inside saveAvailibilty")
    console.log("->",Data)
    const response = await fetch(apiPath + "admin/clinic/addAvailibility",{
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
"Content-Type": "application/json",
      },
      body: JSON.stringify(Data),
    });

    const data = await response.json();
    console.log("data",data);
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: availibility saved");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsClinicDetailsChanged(true);
    
  } catch (error) {
    console.log("Error saving availibilty:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const deleteAvailabilty = async (clinicId,availabilityId) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    console.log("inside deleteAvailibity");
    const response = await fetch(apiPath + "admin/clinic/deleteAvailibility", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        availabilityId:availabilityId
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const verifyOldEmail = async (clinicId,email) => {
  setIsLoading(true);
  try {
    console.log("inside verifyOldEmail");
    const response = await fetch(apiPath + "admin/clinic/verifyEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        email:email
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsOldEmailVerified(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const verifyOtp = async (otp) => {
  setIsLoading(true);
  try {
    console.log("inside verifyotp");
    const response = await fetch(apiPath + "admin/clinic/verifyOtp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp:otp
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsOtpVerified(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const ChangeEmail = async (clinicId,email) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    console.log("inside changeEmail");
    const response = await fetch(apiPath + "admin/clinic/changeEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        email:email
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
    
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const uploadImage = async (Data) => {
  setIsClinicDetailsChanged(false)
  setIsLoading(true);

  try {
    const response = await fetch(apiPath + "admin/clinic/addImage", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "*/*",
      },
      body: Data,
    });

    const data = await response.json();
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: Image Uploaded");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("Error Uploading Image:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const deleteImage = async (clinicId,index) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    console.log("inside deleteImage");
    const response = await fetch(apiPath + "admin/clinic/deleteImage", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        index:index
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const changeCategory = async (categoryData) => {
  setIsClinicDetailsChanged(false);
  // console.log(categoryData)
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admin/clinic/changeCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(categoryData),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const updateClinic = async (Data) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);

  try {
    console.log("inside updateClinic")
    console.log("->",Data)
    const response = await fetch(apiPath + "admin/clinic/updateClinic",{
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
"Content-Type": "application/json",
      },
      body: JSON.stringify(Data),
    });

    const data = await response.json();
    console.log("data",data);
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: clinic details updated");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsClinicDetailsChanged(true);
    
  } catch (error) {
    console.log("Error updating clinic details:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};



const getSubcategory = async (clinicId) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);

  try {
    const response = await fetch(apiPath + "admin/clinic/getSubCategory",{
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
"Content-Type": "application/json",
      },
      body: JSON.stringify({
        // categoryid:categoryid,
        clinicId:clinicId,
        
      }),
    });

    const data = await response.json();
    console.log("data",data);
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setSubCategoryDetails(data.resultData);
    console.log("Success:");
    // setAlert({ type: "success", Message: data.Message });
    // setTimeout(() => {
    //   setAlert({ type: "", Message: "" });
    // }, 3000);

    
    
  } catch (error) {
    console.log("Error :", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const uploadDoc = async (Data) => {
  setIsClinicDetailsChanged(false)
  setIsLoading(true);

  try {
    const response = await fetch(apiPath + "admin/clinic/changeDoc", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "*/*",
      },
      body: Data,
    });

    const data = await response.json();
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: Document Uploaded");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("Error Uploading Document:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const uploadBanner = async (Data) => {
  // setIsClinicDetailsChanged(false)
  setBannerUpdated(false)
  setIsLoading(true);

  try {
    const response = await fetch(apiPath + "admins/uploadBanner", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "*/*",
      },
      body: Data,
    });

    const data = await response.json();
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: Image Uploaded");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    setBannerUpdated(true);
  } catch (error) {
    console.log("Error Uploading Image:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};


const ActivateBanner = async (id,isActive) => {
  // setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admins/changeActivity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id:id,
        isActive:isActive,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const updateIsClickAble = async (id,isClickable) => {
  // setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admins/changeClick", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id:id,
        isClickable:isClickable
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    // setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const uploadBannerLink = async (id,link) => {
  // setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admins/changeBannerLink", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id:id,
        link:link,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

//set index
const setBannerIndex = async (id,index) => {
  // setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admins/setBannerIndex", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id:id,
        index:index,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const updateOffDays = async (clinicId,offDays) => {
  // setIsClinicDetailsChanged(false);
  setIsLoading(true);

  try {
    console.log("inside offDays")
    console.log("->",offDays)
    const response = await fetch(apiPath + "admin/clinic/offDays",{
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        offDays:offDays,
      }),
    });

    const data = await response.json();
    console.log("data",data);
    setIsLoading(false);

    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    console.log("Success: availibility saved");
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    // setIsClinicDetailsChanged(true);
    
  } catch (error) {
    console.log("Error saving availibilty:", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const setSelectedholiday = async (date,id) => {
  setIsClinicDetailsChanged(false);
  try {
    // Perform asynchronous operation to set the selected date
    const response = await fetch(apiPath + "admin/setSelectedDate", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
       "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date:date,
        id:id,
      }),
    });
     
    if (!response.ok) {
      throw new Error("Failed to set selected date");
    }

    // Handle successful response
    const data = await response.json();
    console.log("Selected date set successfully:", data);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    // Handle errors
    console.error("Error setting selected date:", error.message);
  }
};
const deleteHoliday = async (holidayid,id) => {
  setIsLoading(true);
  setIsHolidayDeleted(false);
  try {
    // Perform asynchronous operation to set the selected date
    const response = await fetch(apiPath + "admin/deleteHoliday", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        holidayid:holidayid,
        id:id,
      }),
    });
     
    if (!response.ok) {
      throw new Error("Failet to delete selected date");
    }

    // Handle successful response
    const data = await response.json();
    console.log("Selected date delete successfully:", data);
    setIsHolidayDeleted(true);
    setIsLoading(false);
  } catch (error) {
    // Handle errors
    console.error("Error setting selected date:", error.message);
  }
};


const getHolidays = async (id) => {
  setIsLoading(true);
  setclinicHolidays({
    Success: false,
    Status: 100})
  try {
    const response = await fetch(apiPath + "admin/clinic/getHoliday/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId: id,
      }),
    });
    const data = await response.json();
    setclinicHolidays(data);
    setIsLoading(false);
    console.log("data--->",data)
    
    console.log("...",clinicHolidays);

    if (!data.Success) {
      if (data.Status === 401) {
        console.log("logout----------------->");
        await logout();
      }
      throw new Error(data.message);
    }
    
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const clinicActivation = async (clinicId,isActive) => {
  setIsClinicDetailsChanged(false);
  setIsLoading(true);
  try {
    const response = await fetch(apiPath + "admin/clinic/changeClick", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clinicId:clinicId,
        isActive:isActive
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }
    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
    setIsClinicDetailsChanged(true);
  } catch (error) {
    console.log("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

const editsubCategory = async (editdata) => {
  setIsLoading(true);
  setSavedResponse(false);
  try {
    const response = await fetch(apiPath + "category/subedit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        categoryname: editdata.categoryname,
        categoryid: editdata.categoryid,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    setSavedResponse(true);
    if (!data.Success) {
      if (data.Status === 401) {
        await logout();
      }
      throw new Error(data.Message);
    }

    setAlert({ type: "success", Message: data.Message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);

    fetcher("category/show");
  } catch (error) {
    console.error("error catch------------->", error.message);
    setAlert({ type: "error", Message: error.message });
    setTimeout(() => {
      setAlert({ type: "", Message: "" });
    }, 3000);
  }
};

// holiday

  /* End */

  /* ===========================+++End================================== */

  const logout = async () => {
    localStorage.removeItem("token");
    setLogin(false);
  };

  return (
    <SuperAdminContext.Provider
      value={{
        islogin,
        setLogin,
        logout,
        addCategory,
        alert,
        setAlert,
        fetcher,
        getClinicDetails,
        userData,
        setuserData,
        userDash,
        data,
        setData,
        clinicData,
        isLoading,
        deleteCategory,
        editCategory,
        adminSave,
        getcategoriesdropdown,
        categoryDropDown,
        setCategoryDropDown,
        addSubCategory,
        deleteSubCategory,
        subcategorydropdown,
        getSubcategorybycategory,
        saveClinic,
        savedResponse,
        editAdmin,
        isClinicSaved,
        deleteAdmin,
        saveAvailibility,
        deleteAvailabilty,
        verifyOldEmail,verifyOtp,ChangeEmail,
        isOldEmailVerified,setIsOldEmailVerified,isOtpVerified,setIsOtpVerified,
        uploadImage,deleteImage,isClinicDetailsChanged,
        changeCategory,updateClinic,getSubcategory,subCategoryDetails,uploadDoc,
        uploadBanner,ActivateBanner,updateIsClickAble,uploadBannerLink,setBannerIndex,
        updateOffDays,setSelectedholiday,deleteHoliday,bannerUpdated,getHolidays,clinicHolidays,clinicActivation,isHolidayDeleted,editsubCategory,

      }}
    >
      {props.children}
    </SuperAdminContext.Provider>
  );
};

export default SuperAdminState;
