import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="footer-light">
        <div className="light-layout"></div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    {/* <img src="../assets/images/icon/logo.png" alt="" /> */}
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </p>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i
                            className="fa fa-google-plus"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-rss" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col offset-xl-1">
                        <div className="sub-title">
                            <div className="footer-title">
                                <h4>my account</h4>
                            </div>
                            <div className="footer-contant">
                                <ul>
                                    <li>
                                        <a href="#">mens</a>
                                    </li>
                                    <li>
                                        <a href="#">womens</a>
                                    </li>
                                    <li>
                                        <a href="#">clothing</a>
                                    </li>
                                    <li>
                                        <a href="#">accessories</a>
                                    </li>
                                    <li>
                                        <a href="#">featured</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="sub-title">
                            <div className="footer-title">
                                <h4>why we choose</h4>
                            </div>
                            <div className="footer-contant">
                                <ul>
                                    <li>
                                        <a href="#">shipping & return</a>
                                    </li>
                                    <li>
                                        <a href="#">secure shopping</a>
                                    </li>
                                    <li>
                                        <a href="#">gallary</a>
                                    </li>
                                    <li>
                                        <a href="#">affiliates</a>
                                    </li>
                                    <li>
                                        <a href="#">contacts</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="sub-title">
                            <div className="footer-title">
                                <h4>store information</h4>
                            </div>
                            <div className="footer-contant">
                                <ul className="contact-list">
                                    <li>
                                        <i className="fa fa-map-marker"></i>Multikart Demo Store,
                                        Demo store India 345-659
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i>Call Us: 123-456-7898
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope-o"></i>Email Us:
                                        Support@Fiot.com
                                    </li>
                                    <li>
                                        <i className="fa fa-fax"></i>Fax: 123456
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>
        </section>
      </footer>
      {/* <!-- footer end -->


    <!-- tap to top --> */}
      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      {/* <!-- tap to top End -->


    <!-- theme setting --> */}
      <a href="#" onclick="openSetting()">
        <div className="setting-sidebar" id="setting-icon">
          <div>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </div>
        </div>
      </a>
      <div id="setting_box" className="setting-box">
        <a href="#" className="overlay" onclick="closeSetting()"></a>
      </div>
    </>
  );
}
