import React, { useEffect, useState } from "react";
import SignInSide from "../../../components/SignInSide";
//import Dashboard from "./components/pages/Dashboard";
import { useNavigate } from "react-router-dom";
//import Layout from "./components/pages/Layout";
import UsersLayout from "./UsersLayout";
export default function UsersLanding(props) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("token") ? true : false;
    console.log("check login", user);
    if (user) {
      setUserData(false);
    } else {
      setUserData(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!userData ? (
        <UsersLayout key="dashboard" page={props.page} />
      ) : (
        <SignInSide key="login" />
      )}
    </>
  );
}
