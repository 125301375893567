
import React from 'react';

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('_id', {
    header: 'ID',
    size: 40,
  }),
  columnHelper.accessor('subcategoryname', {
    header: 'Sub-Category',
    size: 120,
  }),
  columnHelper.accessor('category.categoryname', {
    header: 'Category',
    size: 120,
  }),
  columnHelper.accessor('price', {
    header: 'Price',
    size: 120,
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    size: 120,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const DataSubcategories = (props) => {
  const data = props.data;


  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleDelete = (id) => {
    props.deletecategory(id);
  };
  const handleEditcategory = (data) => {
    props.editcategory(data);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: 'last',

    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => handleEditcategory(row.original)}>
        Edit
      </MenuItem>,
      <MenuItem key="delete" onClick={() => handleDelete(row.original._id)}>
        Delete
      </MenuItem>,
    ],
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button

          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default DataSubcategories;
