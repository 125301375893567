// actions/exampleActions.js
import { apiPath } from "../components/constents";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());

    try {
      const response = await fetch(
        "http://localhost:3001/api/auth/superLogin",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "superadmin@admin.com",
            password: "#1234567890",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      dispatch(fetchDataSuccess(data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const addCategory = (category) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());

    try {
      const response = await fetch(apiPath + "category/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categoryname: category,
        }),
      });
      const data = await response.json();
      if (!data.Success) {
        console.log("error------------>", response);
        throw new Error(data.message);
      }
      dispatch(fetchDataSuccess(data));
    } catch (error) {
      console.log(error.message);
      dispatch(fetchDataFailure(error.message));
    }
  };
};
