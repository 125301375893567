import React from "react";
export default function Settings(props) {

  return (
    <>
      <div
        className={
          props.page === "settings"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="settings"
      >
        <div style={{}}>
        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="card-body">
                <div className="dashboard-box ml-4">
                  <div className="dashboard-title">
                    <h4>settings</h4>
                  </div>
                  <div className="dashboard-detail">
                    <div className="account-setting">
                      <h5>Notifications</h5>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              checked
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              Allow Desktop Notifications
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios2"
                              value="option2"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios2"
                            >
                              Enable Notifications
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios3"
                              value="option3"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios3"
                            >
                              Get notification for my own activity
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios4"
                              value="option4"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios4"
                            >
                              DND
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="account-setting">
                      <h5>deactivate account</h5>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios1"
                              id="exampleRadios4"
                              value="option4"
                              checked
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios4"
                            >
                              I have a privacy concern
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios1"
                              id="exampleRadios5"
                              value="option5"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios5"
                            >
                              This is temporary
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios1"
                              id="exampleRadios6"
                              value="option6"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios6"
                            >
                              other
                            </label>
                          </div>
                          <button
                            type="button"
                            className="btn btn-solid btn-xs"
                          >
                            Deactivate Account
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="account-setting">
                      <h5>Delete account</h5>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios3"
                              id="exampleRadios7"
                              value="option7"
                              checked
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios7"
                            >
                              No longer usable
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios3"
                              id="exampleRadios8"
                              value="option8"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios8"
                            >
                              Want to switch on other account
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="radio_animated form-check-input"
                              type="radio"
                              name="exampleRadios3"
                              id="exampleRadios9"
                              value="option9"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios9"
                            >
                              other
                            </label>
                          </div>
                          <button
                            type="button"
                            className="btn btn-solid btn-xs"
                          >
                            Delete Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
