import React, { useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import { apiPath } from "../../components/constents";
import { json, useLocation, useNavigate } from "react-router-dom";
import SuperAdminContext from "../../context/SuperAdminContext";
import Loader from "../notify/Loader";
import { useState } from "react";
import { FaTrashAlt } from 'react-icons/fa';
import { FaPen } from "react-icons/fa6";
import Modal from './Modal';
import { MdOutlineClose } from "react-icons/md";

import EditAvailibity from "./common/EditAvailibity";
import ChangeEmail from "./common/ChangeEmail";

import ShowImage from "./common/SowImage";
import Button from 'react-bootstrap/Button';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ShowDoc from "./common/ShowDoc";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

export default function ClinicDetails(props) {
  const navigate = useNavigate();
  const [clinic, setClinic] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef();
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [showEditAvailibiltyModal, setShowEditAvailibiltyModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [clinicCategory, setClinicCategory] = useState([]);
  const [offDays, setOffDays] = useState([])
  const [isOffDaysEmpty, setIsOffDaysEmpty] = useState(false);


  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [days, setDays] = useState('');
  const [subcategory, setSubcategory] = useState([]);
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const location = useLocation();
  const id = location.state.clinicid;
  const a = useContext(SuperAdminContext);

  const { getClinicDetails, clinicData, deleteAvailabilty, isClinicDetailsChanged,
    subcategorydropdown, data,
    getSubcategorybycategory,
    fetcher,
    changeCategory, updateClinic, getSubcategory, subCategoryDetails, updateOffDays, setSelectedholiday, deleteHoliday, getHolidays, isLoading, clinicHolidays, clinicActivation, isHolidayDeleted
  } = a;

  const isFirstRender = useRef(0);

  useEffect(() => {
    getClinicDetails("admin/getClinicDetail/", id);

  
    const fetchData = async () => {
      try {
        await fetcher("category/show");
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getHolidays(id)
  }, [])
  useEffect(() => {
    getHolidays(id)
  }, [isHolidayDeleted])

  useEffect(() => {
    if (clinicData && clinicData.resultData) {
      const { country, clinicname, website, phone, pincode, city, state, address, categoryid, subcategories } = clinicData.resultData;

      setCountry(country);
      setClinic(clinicname);
      setWebsite(website);
      setPhone(phone);
  
      setClinicCategory(categoryid);
    
      setPincode(pincode);
      setCity(city);
      setState(state);
      setAddress(address);
   
      setSubcategory(subcategories)
   
      if (clinicData.resultData.offdays.length == 0) {
        setIsOffDaysEmpty(true);
      }

    }

  }, [clinicData]);
  // const editAvailibityRef = useRef(null);

  const handleAddnew = () => {
    setShowEditAvailibiltyModal(true);
  };

  const handleClose = () => {
    setShowEditAvailibiltyModal(false);
  };


  const handleClinicUpdate = () => {
  const updatedClinicData = {
    clinicId: id, // Assuming you have the clinic ID available
    clinicname: clinic,
    country: country,
    website: website,
    address: address, // Street address
    city: city,
    state: state,
    zip: pincode, // Zip code
    phone: phone,
  };

  updateClinic(updatedClinicData); 
};


  const handleCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleAddress = (event, field) => {
    const { value } = event.target;
  
    // Directly set the state for each field based on the field parameter
    switch (field) {
      case 'address':
        setAddress(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'zip':
        setPincode(value);
        break;
      default:
        break;
    }
  };
  



  useEffect(() => {
    getClinicDetails("admin/getClinicDetail/", id);
    getHolidays(id);
  }, [isClinicDetailsChanged]);

  useEffect(() => {
    if (isOffDaysEmpty) {
      updateOffDays(id, offDays);
    }
  }, [offDays]);

  function handleDeleteAvailability(availabilityId) {
  
    deleteAvailabilty(id, availabilityId);
  }
  const handleFormData = (event, newValue, dropdownName) => {
  
    setIsOffDaysEmpty(true);
    setOffDays(newValue.map(item => item.label));

  };
  const handleClinic = (event) => {
    setClinic(event.target.value);
  };

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleSubcategory = (event, selectedValues, dropdownName) => {
    setSubcategory(selectedValues);
   
  };

  const isOptionEqualToValue = (option, value) => {
    return (
      option.label === value.label && option.selectedDay === value.selectedDay
    );
  };

  // add holiday
  const handleDateSelect = async (date) => {
    // Mark the function as async
    setShowAddModal(true);
    setShowDatePicker(false);
    setSelectedDate(date);
    setShowDatePicker(true);
 
  };
  const closeAddModal = () => {
    setSelectedDate(null); // Reset selected date
    setShowAddModal(false);

  };

  const confirmAddHoliday = () => {
    if (selectedDate) {
      setSelectedholiday(selectedDate, id);
      closeAddModal();
    }
  };

  const handleAddButtonClick = () => {
    setShowDatePicker(true);
    setTimeout(() => {
      datePickerRef.current.setFocus();
    }, 30);
  };


  // delete holiday

  const confirmDelete = (holidayid) => {

    deleteHoliday(holidayid, id);
  };



  const handleChangeCategory = () => {

    const categorydata = {
      clinicId: id,
      clinicCategory: clinicCategory,
      subcategories: subcategory
    }
 
    changeCategory(categorydata);
  }

  if (clinicData.Success) {
    const allDays = {
      "Monday": 0,
      "Tuesday": 1,
      "Wednesday": 2,
      "Thursday": 3,
      "Friday": 4,
      "Saturday": 5,
      "Sunday": 6
    }
    const indexing = {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
      6: "Sunday"
    }
  
    let availableDays = [];
    let k = 0;
    for (let i = 0; i < clinicData.resultData.availability.length; i++) {
      for (let j = allDays[clinicData.resultData.availability[i].fromday]; j <= allDays[clinicData.resultData.availability[i].today]; j++) {
        availableDays[k] = indexing[j];
        k++;
      }
    }

    let notAvailableDays = [];

    for (let day in allDays) {
      if (!availableDays.includes(day)) {
        notAvailableDays.push(day);
      }
    }


    let dropdown = notAvailableDays.map(
      (day, index) => ({
        label: day,
        selectedDay: day,
      })
    );




    return (
      <>
        <div className="clinic-details-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <div className="clinic-details" style={{ flex: 1, paddingRight: '20px' }}></div> */}
          <div
            className={
              props.page === "clinicDetail"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="clinicDetail"

          >
            <div className="row">
              <div className="col-12">
                <div className="card mt-0">
                  <div className="card-body">
                    <div className="dashboard-box">
                      <div className="dashboard-title">
                        <h4>Clinic Details </h4>

                      </div>
                      <div className="dashboard-detail">
                        <ul>

                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>isActive</h6>
                                {/* <Badge bg="success"> Active</Badge> */}
                              </div>
                              <div className="right">
                                <h6>{clinicData.resultData.is_active === true ? 'Active' : 'Not Active'}
                                  <span
                                    data-toggle="modal"
                                    data-target="#edit-profile"
                                    onClick={() => clinicActivation(id, clinicData.resultData.is_active)}
                                  >
                                    {clinicData.resultData.is_active === true ? 'Deactivate' : 'Activate'}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="details">
                              <div className="left">
                                <h6 >Clinic name</h6>
                              </div>
                              <div className="right">
                                <h6>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={clinic}
                                    placeholder={clinicData.resultData.clinicname}
                                    onChange={handleClinic}
                                  />
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Phone </h6>
                              </div>
                              <div className="right">
                                <h6>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={phone}
                                    placeholder={clinicData.resultData.phone}
                                    onChange={handlePhone}
                                  />
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>email address</h6>
                              </div>
                              <div className="right">
                                <h6>
                                  {clinicData.resultData.email}

                                  <span
                                    data-toggle="modal"
                                    data-target="#edit-profile"
                                  // onClick={()=>setShowChangeEmailModal(true)}
                                  >
                                    {/* change */}
                                    <FaPen onClick={() => setShowChangeEmailModal(true)} />
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Website</h6>
                              </div>
                              <div className="right">
                                <h6>
                                  <input
                                    type="text"
                                    value={website}
                                    class="form-control"
                                    placeholder={clinicData.resultData.website}
                                    onChange={handleWebsite}
                                  />
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Availibility</h6>
                              </div>
                              <div className="right">
                                <Table striped>
                                  <thead>
                                    <tr>
                                      <th colSpan={4}>
                                        <span
                                          data-toggle="modal"
                                          data-target="#edit-profile"
                                          onClick={handleAddnew}
                                        >
                                          Add new
                                        </span>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>#</th>
                                      <th>From</th>
                                      <th>To</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      clinicData.resultData.availability.map((element, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {element.fromday}{" "}
                                              {element.fromtime}
                                            </td>
                                            <td>
                                              {element.today}{" "}
                                              {element.totime}
                                            </td>
                                            <td>

                                              <FaTrashAlt
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleDeleteAvailability(element._id)}
                                              // data-dismiss="modal"
                                              />
                                              {/* </span> */}
                                            </td>
                                          </tr>)
                                      })
                                    }

                                  </tbody>
                                </Table>

                              </div>

                            </div>

                          </li>
                          {showEditAvailibiltyModal && (
                            <EditAvailibity
                              show={showEditAvailibiltyModal}
                              handleClose={handleClose}
                              id={id}
                            />)}
                          <li>
                            <div className=" details ">
                              <div className=" left"><h6>OffDay</h6></div>
                              <div className="right " style={{ width: '100%' }} >
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                  <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    // class="form-control"
                                    options={dropdown}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    sx={{ backgroundColor: "white" }}
                                    onChange={(event, newValue) =>
                                      handleFormData(event, newValue, "billtype")
                                    }
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.label}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Off day"
                                        placeholder="Sunday"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          {/* Add holidays */}
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Add Holidays</h6>
                              </div>
                              <div className="right">
                                {showDatePicker && (
                                  <DatePicker
                                    ref={datePickerRef}
                                    selected={selectedDate}
                                    onChange={handleDateSelect}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select date"
                                    inline
                                  />
                                )}
                                {showDatePicker ? <MdOutlineClose onClick={() => setShowDatePicker(false)} style={{ cursor: 'pointer' }}
                                  onMouseOver={(e) => e.currentTarget.style.cursor = 'pointer'} /> :
                                  <button className="btn btn-primary view btn-secondary" onClick={handleAddButtonClick}>Add</button>
                                }
                              </div>
                            </div>
                            <Modal show={showAddModal} onClose={closeAddModal} onConfirm={confirmAddHoliday}>
                              Are you sure you want to add this date to the holiday list?
                            </Modal>
                          </li>
                          {/* delete holiday */}
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Delete Holidays</h6>
                              </div>


                              <button type="button" className="btn btn-primary view btn-secondary btn-danger" data-toggle="modal" data-target="#exampleModalCenter" >
                                View and Delete
                              </button>

                              <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title" id="exampleModalLongTitle">Holidays</h5>
                                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Date</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {isLoading && <Loader></Loader>}
                                          {!isLoading && clinicHolidays?.Success && clinicHolidays?.resultData[0]?.holidays.map((holiday) => (
                                            <tr key={holiday._id}>
                                              <td>{new Date(holiday.date).toLocaleDateString()}</td>
                                              <td>
                                                <FaTrashAlt
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => confirmDelete(holiday._id)}
                                                // data-dismiss="modal"
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="modal-footer">
                                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </li>
                          <li>
                            <div className="details">
                              <div className="left" >
                                <h6>Country</h6>
                              </div>
                              <div className="right">
                                {/* <h6>{clinicData.resultData.country}</h6> */}
                                <select onChange={handleCountry} class="form-control" >
                                  <option value="" selected disabled>
                                    {clinicData.resultData.country}
                                  </option>
                                  <option value="India">India</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="United State">United State</option>
                                  <option value="Australia">Australia</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>category</h6>
                              </div>
                              <div className="right">
                                <h6>
                                  {clinicCategory.map((category) => category.categoryname).join(', ')}
                                </h6>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Subcategory</h6>
                              </div>
                              <div className="right">
                                <h6>

                                  {subcategory.map((category) => `${category.subcategoryname}  $${category.price}`).join(', ')}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Street Address</h6>
                              </div>
                              <div className="right">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Street Address"
                                  value={address}
                                  onChange={(event) => handleAddress(event, 'address')}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>City</h6>
                              </div>
                              <div className="right">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  value={city}
                                  onChange={(event) => handleAddress(event, 'city')}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>State</h6>
                              </div>
                              <div className="right">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                  value={state}
                                  onChange={(event) => handleAddress(event, 'state')}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Zip</h6>
                              </div>
                              <div className="right">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Zip"
                                  value={pincode}
                                  onChange={(event) => handleAddress(event, 'zip')}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="details">
                              <div className="left">
                                <h6>Images</h6>
                              </div>
                              <div className="right">
                                <Button variant="secondary" onClick={() => setShowImageModal(!showImageModal)}>{showImageModal ? "Hide" : "Show"}</Button>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="details">
                              <div className="left">
                                <Button variant="secondary" onClick={handleClinicUpdate}>Save Changes</Button>
                              </div>
                            </div>
                          </li>
                          {showImageModal && <ShowImage images={clinicData.resultData.images} clinicId={id} />}

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>


          <div className="show-doc" style={{ flex: 1 }}>
            <ShowDoc doc={clinicData.resultData.doc_file[0]} clinicId={id}></ShowDoc>
          </div>
        </div>

        {showChangeEmailModal && <ChangeEmail showChangeEmailModal={showChangeEmailModal} setShowChangeEmailModal={setShowChangeEmailModal} id={id} oldEmail={clinicData.resultData.email} />}

      </>
    );
  } else {
    <Loader />;
  }
}
