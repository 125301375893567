import React from "react";
import Sidenav from "./common/Sidenav";
/* import Admins from "../Admins";
import Categories from "./Categories"; */
/* import DashboardContent from ""; */
import UdashContent from "./UdashContent";
import UsersearchHistory from "./UsersearchHistory";

export default function UsersDash(props) {
  console.log("page--------->", props);
  const mainpage = props.page;
  return (
    <>
      <section className="dashboard-section section-b-space">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidenav page={props.page} />
            </div>
            <div className="col-lg-10">
              <div className="faq-content tab-content">
                {mainpage === "users-home" && (
                  <UdashContent page={props.page} />
                )}
                {mainpage === "usersearchHistory" && (
                  <UsersearchHistory page={props.page} />
                )}
                {/* {mainpage === "categories" && <Categories page={props.page} />}
                {mainpage === "admins" && <Admins page={props.page} />}
                {mainpage === "profile" && <Profile page={props.page} />}
                {mainpage === "settings" && <Settings page={props.page} />} */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  dashboard section end -->
      

    <!-- Modal start --> */}
    </>
  );
}
