import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AdminsData from "../../lookups/AdminsData";
import SuperAdminContext from "../../context/SuperAdminContext";
import Loader from "../notify/Loader";

export default function Admins(props) {
  const a = useContext(SuperAdminContext);
  const { adminSave, fetcher, data, savedResponse, editAdmin, deleteAdmin } = a;
  const [smShow, setSmShow] = useState(false);
  const [lgShowedit, setLgShowedit] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [adminData, setAdminData] = useState({
    fullname: "",
    username: "",
    email: "",
    otp: "",
    phonenumber: "",
    password: "",
    confirmPass: "",
  });
  const [editAdminData, seteditAdminData] = useState({
    fullname: "",
    username: "",
    email: "",
    otp: "",
    phonenumber: "",
    /*  password: "",
    confirmPass: "", */
    adminid: "",
  });

  useEffect(() => {
    if (savedResponse) {
      setAdminData({
        fullname: "",
        username: "",
        email: "",
        otp: "",
        phonenumber: "",
        password: "",
      });

      setLgShowedit(false);
      setLgShow(false);
    }
    fetcher("admins/admins_getall");
  }, [savedResponse]);

  const handleformdata = (e, label) => {
    const value = e.target.value;
    setAdminData({
      ...adminData,
      [label]: value,
    });
  };

  const handleeditformdata = (e, label) => {
    const value = e.target.value;
    seteditAdminData({
      ...editAdminData,
      [label]: value,
    });
  };

  const handleSubmit = () => {
    adminSave(adminData);
  };
  const handleEditAdmin = (data) => {
    console.log("edit Admin----------->", data);
    seteditAdminData(data);
    setLgShowedit(true);
  };
  const handleDeleteAdmin = (id) => {
    console.log("delte admin------>", id);
    deleteAdmin(id);
  };

  const handleSubmitEdit = () => {
    editAdmin(editAdminData);
  };

  /* const confirmPass (event.taget) =>{

  } */
  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New Admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Full Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Fullname"
              value={adminData.fullname}
              onChange={(event) => handleformdata(event, "fullname")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Username</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Username"
              value={adminData.username}
              onChange={(event) => handleformdata(event, "username")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="email"
              value={adminData.email}
              onChange={(event) => handleformdata(event, "email")}
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Phone Number</label>
            <input
              type="number"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Phone Number"
              value={adminData.phonenumber}
              onChange={(event) => handleformdata(event, "phonenumber")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              autoComplete="off"
              value={adminData.password}
              onChange={(event) => handleformdata(event, "password")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Confirm-Password</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              autoComplete="off"
              aria-describedby="emailHelp"
              value={adminData.confirmPass}
              onChange={(event) => handleformdata(event, "confirmPass")}
            />
          </div>
          {/* <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="file" class="form-control" />
            <small id="emailHelp" class="form-text text-muted">
              File type jpg,png,jpeg max:100 mb
            </small>
          </div> */}

          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>

      {/* Edit MOdal */}
      <Modal
        size="lg"
        show={lgShowedit}
        onHide={() => setLgShowedit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Full Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Fullname"
              value={editAdminData.fullname}
              onChange={(event) => handleeditformdata(event, "fullname")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Username</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Username"
              value={editAdminData.username}
              onChange={(event) => handleeditformdata(event, "username")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="email"
              value={editAdminData.email}
              onChange={(event) => handleeditformdata(event, "email")}
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Phone Number</label>
            <input
              type="number"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Phone Number"
              value={editAdminData.phonenumber}
              onChange={(event) => handleeditformdata(event, "phonenumber")}
            />
          </div>
          {/* <div class="form-group">
            <label for="exampleInputEmail1">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              autoComplete="off"
              value={editAdminData.password}
              onChange={(event) => handleformdata(event, "password")}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Confirm-Password</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              autoComplete="off"
              aria-describedby="emailHelp"
              value={editAdminData.confirmPass}
              onChange={(event) => handleformdata(event, "confirmPass")}
            />
          </div> */}
          {/* <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="file" class="form-control" />
            <small id="emailHelp" class="form-text text-muted">
              File type jpg,png,jpeg max:100 mb
            </small>
          </div> */}

          <button
            type="button"
            class="btn btn-primary float-rigth"
            onClick={handleSubmitEdit}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>
      {/* End */}
      <div
        className={
          props.page === "admins"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="orders"
      >
        <div className="row">
          <div className="col-12">
            <div className="card dashboard-table mt-0">
              <div className="card-body">
                <div className="top-sec">
                  <h3>All Admins</h3>
                  <a
                    href="#"
                    className="btn btn-sm btn-solid"
                    onClick={() => setLgShow(true)}
                  >
                    add Admin
                  </a>
                </div>
                {data.Status == 100 ? (
                  <Loader />
                ) : (
                  <AdminsData
                    data={data.resultData}
                    handleDeleteAdmin={handleDeleteAdmin}
                    handleEditAdmin={handleEditAdmin}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
