import { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import SuperAdminContext from '../../../context/SuperAdminContext';

const ChangeEmail=({showChangeEmailModal, setShowChangeEmailModal,id,oldEmail}) =>{
  const [email,setEmail]=useState(oldEmail);
  const [otp,setOtp]=useState("");
    const [isOtpSent,setIsOtpSent]=useState(false);
    
    const a =useContext(SuperAdminContext);
    const {verifyOldEmail,verifyOtp,ChangeEmail,
      isOldEmailVerified,setIsOldEmailVerified,
      isOtpVerified,setIsOtpVerified,
      isEmailChanged,setIsEmailChanged
    }=a
    

    const handleChangeEmail=()=>{
      console.log("email",email);
      console.log("clinicId",id);
      setIsOldEmailVerified(false);
      setIsOtpVerified(false);
      setShowChangeEmailModal(false);
      ChangeEmail(id,email);
    }

    const handleSendOtp=()=>{
      console.log("email",email);
      console.log("clinicId",id);
      verifyOldEmail(id,email);

    }

    const handleVerifyOtp=()=>{
      console.log("otp",otp)
      verifyOtp(otp)
    }

  return (
    <>
      <Modal show={showChangeEmailModal} onHide={() => setShowChangeEmailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          {!isOldEmailVerified && 
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              
              <Form.Label>Enter Your Old Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder={oldEmail}
                // autoFocus
                value={oldEmail}
                onChange={(e)=>setEmail(e.target.value)}
              />
          
            </Form.Group>
            }
                      {/* {isOldEmailVerified && !isOtpVerified &&
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              
              <Form.Label>Enter the otp sent on Your Old Email address</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                onChange={(e)=>setOtp(e.target.value)}
              />
          
            </Form.Group>
            } */}

{isOldEmailVerified && 
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              
              <Form.Label>Enter Your New Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                onChange={(e)=>setEmail(e.target.value)}
              />
          
            </Form.Group>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowChangeEmailModal(false)}>
            Close
          </Button>{!isOldEmailVerified && 
          <Button variant="primary" onClick={handleSendOtp}>
            verify email
          </Button>}
          {/* {isOldEmailVerified && !isOtpVerified &&
          <Button variant="primary" onClick={handleVerifyOtp}>
            verify otp
          </Button>} */}
          {isOldEmailVerified &&
          <Button variant="primary" onClick={handleChangeEmail}>
            change email
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeEmail;