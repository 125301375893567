import React from "react";
import BarChart from "../charts/BarChart";
import DonutChart from "../charts/DonutChart";

export default function ClinicDashContent() {
  return (
    <>
      <div className="tab-pane fade show active" id="dashboard">
        <div className="counter-section">
          <div className="row">
            <div className="col-md-4">
              <div className="counter-box">
                <img
                  src="../assets/images/icon/dashboard/order.png"
                  className="img-fluid"
                />
                <div>
                  <h3>25</h3>
                  <h5>total clinics</h5>
                </div>
              </div>
            </div>
            {/*  <div className="col-md-4">
              <div className="counter-box">
                <img
                  src="../assets/images/icon/dashboard/sale.png"
                  className="img-fluid"
                />
                <div>
                  <h3>12500</h3>
                  <h5>total appointments</h5>
                </div>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="counter-box">
                <img
                  src="../assets/images/icon/dashboard/homework.png"
                  className="img-fluid"
                />
                <div>
                  <h3>50</h3>
                  <h5>On Boarding Clinics</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <DonutChart />
                {/* <div id="chart"></div> */}
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <BarChart />
                {/* <div id="chart-order"></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card dashboard-table">
              <div className="card-body">
                <h3>trending Clinics Today</h3>
                {/*  <DashboardData /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card dashboard-table">
              <div className="card-body">
                <h3>Todys Appointments</h3>

                {/* <DashboardDataTwo /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
