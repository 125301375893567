import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SuperAdminContext from '../../../context/SuperAdminContext';
import '../Modal.css';

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

const EditAvailibility = ({ show, handleClose, id }) => {
  const context = useContext(SuperAdminContext);
  const { saveAvailibility } = context;

  const [totime, setToTime] = useState('');
  const [fromtime, setFromTime] = useState('');
  const [availabeityDay, setAvailabeityDay] = useState({
    fromday: '',
    today: '',
  });

  const handleEditAvailibility = () => {
    const data = {
      totime,
      fromtime,
      fromday: availabeityDay.fromday,
      today: availabeityDay.today,
      id,
    };
    saveAvailibility(data);
    handleClose();
  };

  const handleDayAvailable = (event, label) => {
    setAvailabeityDay({
      ...availabeityDay,
      [label]: event.target.value,
    });
  };

  const handleToTime = (event, newValue) => {
    setToTime(newValue);
  };

  const handleFromTime = (event, newValue) => {
    setFromTime(newValue);
  };

  return (
    <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Availability</h5>
            <button type="button" className="close" aria-label="Close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label label">From Day</label>
              <div className="col-sm-8">
                <select
                  onChange={(event) => handleDayAvailable(event, 'fromday')}
                  className="form-control"
                >
                  <option value="" selected disabled>Days</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label label">To Day</label>
              <div className="col-sm-8">
                <select
                  onChange={(event) => handleDayAvailable(event, 'today')}
                  className="form-control"
                >
                  <option value="" selected disabled>Days</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label label">From Time</label>
              <div className="col-sm-8">
                <Autocomplete
                  id="from-time"
                  options={timeSlots}
                  onChange={handleFromTime}
                  renderInput={(params) => <TextField {...params} label="Time" />}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label label">To Time</label>
              <div className="col-sm-8">
                <Autocomplete
                  id="to-time"
                  options={timeSlots}
                  onChange={handleToTime}
                  renderInput={(params) => <TextField {...params} label="Time" />}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            <button type="button" className="btn btn-secondary" onClick={handleEditAvailibility}>Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAvailibility;
