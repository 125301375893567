import React, { useState, useContext, useEffect } from "react";
import ClinicData from "../../../lookups/ClinicData";
import SuperAdminContext from "../../../context/SuperAdminContext";
import Loader from "../../notify/Loader";

export default function ClinicList(props) {
  const a = useContext(SuperAdminContext);
  const { data, fetcher } = a;

  useEffect(() => {
    fetcher("admin/getallClinics/");
    console.log("clll",data);
  }, []);

  return (
    <>
      <div
        className={
          props.page === "clinicLiist"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="clinicLiist"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card dashboard-table">
              <div className="card-body">
                <div className="top-sec">
                  <h3>Clinics List</h3>
                  {/*  <a
                    href="#"
                    className="btn btn-sm btn-solid"
                    onClick={() => setLgShow(true)}
                  >
                    add department
                  </a> */}
                </div>
                {/* <ClinicData /> */}

                {data.Status == 100 ? (
                  <Loader />
                ) : (
                  <ClinicData data={data.resultData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
