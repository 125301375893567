import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DashboardDataTwo from "../../lookups/DashboardDataTwo";

export default function TreatmentBookings(props) {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
      <div
        className={
          props.page === "treatment-bookings"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="orders"
      >
        <div className="row">
          <div className="col-12">
            <div className="card dashboard-table mt-0">
              <div className="card-body">
                <div className="top-sec">
                  <h3>Treatment Bookings</h3>
                </div>
                <DashboardDataTwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
