import React, { useEffect, useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { apiPath } from "./constents";
import Container from '@mui/material/Container';
import SuperAdminContext from "../context/SuperAdminContext";
import Alerts from "./notify/Alerts";
//import wallpaperImage from "../../assets/images/icon/logo.jpeg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        carloon.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  const a = useContext(SuperAdminContext);
  const { setLogin, setAlert } = a;
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let bodyContent = JSON.stringify({
      email: data.get("email"),
      password: data.get("password"),
    });
    let json = {};
    let apipath = apiPath + "auth/superLogin";


    fetch(apipath, {
      method: "POST",
      body: bodyContent,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("invalid login");
        }
      })

      .then((data) => {
        json = data;

        if (json.Success) {
          /* let jsonprsd = JSON.stringify(json.result_data); */
          setLogin(true);

          localStorage.setItem("token", json.resultData.token);
          localStorage.setItem("type", json.resultData.data.u_type);
          
          if (json.resultData.data.u_type === 1) {
            navigate("/admins/adminDash", { replace: true });
          } else {
            navigate("/home/dashboard", { replace: true });
          }
        } else {
        }
      })
      .catch((error) => {
        setAlert({ type: "error", Message: error.message });
        setTimeout(() => {
          setAlert({ type: "", Message: "" });
        }, 3000);
      });

  };

  const styles = {
    body_img: {
      width: "75%",
      display: "flex",
      margin: "0px",
      padding: "0px",
    },
  };

  return (
    <ThemeProvider theme={defaultTheme} >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img 
  src="../assets/images/icon/logo.jpeg" 
  alt="Company Logo" 
  style={{ 
    width: '100px', 
    marginBottom: '20px', 
    borderRadius: '10%' 
  }} 
/>

          </Box>
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
}