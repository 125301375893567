import React, { useState, useContext, useEffect } from "react";
import ClinicData from "../../lookups/ClinicData";
import SuperAdminContext from "../../context/SuperAdminContext";
import Loader from "../notify/Loader";
import { apiPath } from "../constents"

export default function ClinicList(props) {
  const { setAlert, logout } = useContext(SuperAdminContext);

  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const [data1, setDataState] = useState({
    Success: false,
    Status: 100,
  });

  const getAdminClinic = async () => {
    setIsLoading(true);
    try {
  
      const response = await fetch(apiPath+"admin/getClinics", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
        }),
      });

      const result = await response.json();
    

      if (!result.Success) {
        if (result.Status === 401) {
   
          await logout();
        }
        throw new Error(result.Message);
      }
  
      setDataState(result);
   
    } catch (error) {
     
      setAlert({ type: "error", Message: error.message });
      setTimeout(() => {
        setAlert({ type: "", Message: "" });
      }, 3000);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    getAdminClinic();
  }, []);

  return (
    <>
      <div
        className={
          props.page === "clinicLiist"
            ? "tab-pane fade show active"
            : "tab-pane fade"
        }
        id="clinicLiist"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card dashboard-table">
              <div className="card-body">
                <div className="top-sec">
                  <h3>Clinics List</h3>
                </div>

                {isLoading && 
                  <Loader />
}
                 { data1.Success &&
                  <ClinicData data={data1.resultData} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
